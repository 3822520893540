<template>
	<div @click="gotoservice" class="fixed z-50 h-16 w-16 bottom-16 right-2 bg-emerald-700 rounded-full flex items-center justify-center" >
		<i v-if="false" class="van-badge__wrapper van-icon van-icon-comment-o" style="color: rgb(255, 255, 255); font-size: 30px;">
		</i>
		<img :src="serviceimg" style="width: 30px;" />
	</div>
</template>

<script>
	import serviceimg from '../../static/nft/service.png'
	export default {
		data() {
		    return {
		        serviceimg:serviceimg
		    }
		},
		methods: {
			gotoservice(){
				window.open('https://tawk.to/chat/660ecdfda0c6737bd12867b7/1hqkt99pb')
			}
		}
	}
</script>

<style>
</style>