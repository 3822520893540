<template>
	<div class="daikuans" v-if="dkstatus" @click="gotodaikuan">
		<img src="../../static/nft/home/daikuan/icon-wallet2.png" />
	</div>
	
	<van-dialog style="background: transparent" v-model:show="dkkstatus" show-cancel-button :closeOnClickOverlay="true" :showConfirmButton="false" :showCancelButton="false">
		
		<div  class="dialog-wrap">
			<!---->
			<div  class="dialog-content">
				<div  class="dialog-content">
					<img  src="../../static/nft/home/daikuan/borrow-window-bg.6bea1b9a.png" alt="" class="bg">
					<div  class="dialog-main">
						<div  class="tag">
							<img  src="../../static/nft/home/daikuan/icon-1.png" alt="" class="tag-bg">
							<div  class="tag-box"> {{$t('hometab.text1')}} </div>
						</div>
						<div  class="text-content">
							<div  class="title golden">{{$t('hometab.text2')}}</div>
							<div  class="text golden"> {{$t('hometab.text3')}} </div>
						</div>
						<div  class="button-view">
							<div  class="button-item">
								<div  class="button-box">{{$t('hometab.text4')}}</div>
								<div  class="tip golden"> {{$t('hometab.text5')}} </div>
							</div>
							<div  class="button-item">
								<div  class="button-box">{{$t('hometab.text6')}}</div>
								<div  class="tip golden">{{$t('hometab.text7')}}</div>
							</div>
						</div>
						<div  class="info-view">
							<div  class="tip">{{$t('hometab.text8')}}</div>
							<div  class="line"></div>
							<div  class="tr">
								<div  class="th">{{$t('hometab.text9')}}</div>
								<div  class="th">{{$t('hometab.text10')}}</div>
								<div  class="th">{{$t('hometab.text11')}}</div>
							</div>
							<div  class="line"></div>
							<div  class="tr">
								<div  class="td">{{dkinfo.num}} USDT</div>
								<div  class="td">{{dkinfo.tian}} {{$t('hometab.text10')}}</div>
								<div  class="td">{{dkinfo.interest}} USDT</div>
							</div>
						</div>
					</div>
				</div>
				<div  class="button-yes" @click="gotodaikuantiaokuang">
					<img  src="../../static/nft/home/daikuan/icon-2.png" alt="" class="link-btn">
					<div  class="button-box"> {{$t('hometab.text12')}}
						<img  src="../../static/nft/home/daikuan/icon-3.png" alt="" class="link-icon">
					</div>
				</div>
			</div>
		</div>
		
	</van-dialog>
	
	<van-dialog v-model:show="dktkstatus" show-cancel-button :showConfirmButton="false" :showCancelButton="false">
		
		<div  class="dialog-wrap bgColor">
			<div  class="dialog-head">
				<div  class="title">{{$t('hometab.text13')}}</div>
				<i @click="guanbidkgz"  class="close-icon van-icon van-icon-cross">
				</i>
			</div>
			<div  class="dialog-content">
				<div  class="rule-content">
					<div  class="rule-main">
						<p>{{$t('hometab.text14')}}</p>
						<p>{{$t('hometab.text15')}}</p>
						<p>{{$t('hometab.text16')}}</p>
						<p>{{$t('hometab.text17')}}</p>
						<p>{{$t('hometab.text18')}}</p>
						<p>{{$t('hometab.text19')}}</p>
						<p>{{$t('hometab.text20')}}</p>
						<p>{{$t('hometab.text21')}}</p>
						<p>{{$t('hometab.text22')}}</p>
						<p>{{$t('hometab.text23')}}</p>
						<p>{{$t('hometab.text24')}}</p>
						<p>{{$t('hometab.text25')}}</p>
						<p>{{$t('hometab.text26')}}</p>
						<p>{{$t('hometab.text27')}}</p>
						<p>{{$t('hometab.text28')}}</p>
						<p>{{$t('hometab.text29')}}</p>
						<p>{{$t('hometab.text30')}}</p>
						<p>{{$t('hometab.text31')}}</p>
						<p>{{$t('hometab.text32')}}</p>
						<p>{{$t('hometab.text33')}}</p>
					</div>
					<div @click="daikuansub"   class="button btn-view sincere" style="width: 100%; border-radius: 44px;"> {{$t('hometab.text34')}} </div>
				</div>
			</div>
		</div>
		
	</van-dialog>
	
	<van-dialog v-model:show="successDia" show-cancel-button :closeOnClickOverlay="true" :showConfirmButton="false" :showCancelButton="false">
		<img class="successImg" src="../../static/nft/home/success.png">
	</van-dialog>	
</template>

<script>
	import { getloanstatus,togetloansub } from '@/api/hometab'
	export default {
		data() {
		    return {
				dkstatus:false,
				dkkstatus:false,
				dktkstatus:false,
				successDia:false,
				dkinfo:[]
		    }
		},
		mounted() {
			this.dkonload()
		},
		methods: {
			dkonload(){
				getloanstatus().then(res => {
					if(res.data.status !==0 && res.data.info != null && res.data.info != ''){
						this.dkstatus = true
						this.dkinfo = res.data.info
					}
				}).catch((error) => {
					console.log(error.message)
				})
			},
			gotodaikuan(){
				this.dkkstatus = true
			},
			gotodaikuantiaokuang(){
				this.dktkstatus = true
				this.dkkstatus = false
			},
			guanbidkgz(){
				this.dktkstatus = false
			},
			daikuansub(){
				 togetloansub().then(res => {
					this.dktkstatus = false
					this.successDia = true
					const that = this
					setTimeout(()=>{
						that.$router.push({
						    path: "/capital",
							query: {
							  active: 1
							}
						});
					},1000);
					
				 }).catch((error) => {
				 	console.log(error.message)
					showToast(error.message)
				 })
			}
		}
	}
</script>

<style scoped>
	.daikuans{
		position: fixed;
		bottom: 140px;
		right: 10px;
		z-index: 50;
		width: 60px;
	}
	.van-dialog{
		background: transparent !important;
	}
	
	.dialog-wrap.bgColor{
		background-color: #fff
	}
	
	.dialog-wrap .dialog-head{
		height: 16.267vw;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border: .267vw solid #f4f5f7;
		box-sizing: border-box
	}
	
	.dialog-wrap .dialog-head .title{
		font-size: 4vw;
		font-weight: 500;
		color: #27282c
	}
	
	.dialog-wrap .dialog-head .close-icon{
		font-size: 5.333vw;
		color: #a7aaae;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 4.8vw
	}
	
	.golden {
		background: linear-gradient(105deg, #facd6f, #ffe8ba 41%, #fcd27e);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent
	}
	
	.dialog-content {
		position: relative;
		height: calc(100% + 10.667vw)
	}
	
	.dialog-content .bg {
		width: 100%;
		display: block
	}
	
	.dialog-content .dialog-main {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0
	}
	
	.dialog-content .dialog-main .tag {
		width: 21.333vw;
		position: relative;
		top: 2%
	}
	
	.dialog-content .dialog-main .tag .tag-bg {
		width: 100%;
		display: block
	}
	
	.dialog-content .dialog-main .tag .tag-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 3.0vw;
		font-weight: 500;
		color: #362977;
		display: flex;
		align-items: center;
		padding-left: 3.733vw;
		box-sizing: border-box
	}
	
	.dialog-content .dialog-main .text-content {
		padding: 7.733vw 8.533vw 0;
		box-sizing: border-box;
		color: #2b1773;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center
	}
	
	.dialog-content .dialog-main .text-content .title {
		font-size: 7.267vw;
		font-weight: 600;
		line-height: 10.667vw
	}
	
	.dialog-content .dialog-main .text-content .text {
		font-size: 2.733vw;
		font-weight: 400;
		line-height: 4.267vw
	}
	
	.dialog-content .dialog-main .button-view {
		width: calc(100% - 13.067vw);
		height: 21.333vw;
		position: absolute;
		left: 6.533vw;
		top: 57.467vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between
	}
	
	.dialog-content .dialog-main .button-view .button-item {
		display: flex;
		justify-content: space-between;
		background: #261e18;
		border-radius: 2.133vw
	}
	
	.dialog-content .dialog-main .button-view .button-item .button-box {
		height: 9.333vw;
		background: linear-gradient(90deg, #62ecb7, #a3f4d5 22%, #68edb9 47%, #a4f4d6 69%, #62ecb7);
		border-radius: 2.133vw;
		box-sizing: border-box;
		padding: 0 5.067vw;
		font-size: 3.533vw;
		font-weight: 500;
		color: #362977;
		display: flex;
		align-items: center
	}
	
	.dialog-content .dialog-main .button-view .button-item .tip {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 2.667vw
	}
	
	.dialog-content .dialog-main .info-view {
		width: calc(100% - 13.067vw);
		height: 33.333vw;
		position: absolute;
		top: 78.8vw;
		left: 6.533vw;
		background-color: rgba(0, 0, 0, .12);
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		padding: 0 7.467vw;
		background-color: #9ea8c0
	}
	
	.dialog-content .dialog-main .info-view .tip {
		font-size: 3.267vw;
		font-weight: 500;
		color: #4d11b6;
		text-align: center;
		margin-bottom: 1.6vw
	}
	
	.dialog-content .dialog-main .info-view .line {
		width: calc(100% - 8vw);
		margin: 1.6vw auto;
		border: .267vw solid #342174;
		height: 0
	}
	
	.dialog-content .dialog-main .info-view .tr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 8vw);
		margin: 0 auto;
		font-size: 2.733vw;
		color: #4d11b6
	}
	
	.dialog-content .dialog-main .info-view .tr .th {
		font-weight: 500
	}
	
	.dialog-content .dialog-main .info-view .tr .td {
		font-weight: 700
	}
	
	.dialog-content .button-yes {
		position: relative
	}
	
	.dialog-content .button-yes .link-btn {
		width: 100%;
		display: block
	}
	
	.dialog-content .button-yes .button-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 4.067vw;
		font-weight: 600;
		color: #fff
	}
	
	.dialog-content .button-yes .button-box .link-icon {
		width: 4.267vw;
		margin-left: 2.133vw;
		display: block
	}
	
	
	.button {
		width: 100%;
		height: 11.733vw;
		border-radius: 2.133vw;
		opacity: .98;
		border: .267vw solid #adb0bb;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 4.067vw;
		font-weight: 500;
		color: #adb0bb
	}
	
	.button.is-disabled {
		pointer-events: none
	}
	
	.button.sincere {
		background-color: #253340;
		border-color: #253340;
		color: #fff
	}
	
	.button.white {
		background-color: #fff;
		border-color: #fff;
		color: #1a1b1c
	}
	
	.button.disabled .button.info {
		background-color: #cbcbcb;
		border-color: #cbcbcb;
		color: #fff
	}
	
	.button.disabled {
		pointer-events: none
	}
	
	.rule-content {
	    padding: 4vw;
	    box-sizing: border-box;
	}
	.rule-content .rule-main{
	    height: 40vh;
	    overflow-y: auto;
	    margin-bottom: 4vw;
		color:#000000;
	}
	.button.sincere {
	    background-color: #253340;
	    border-color: #253340;
	    color: #fff;
	}
	.button {
	    width: 100%;
	    height: 11.733vw;
	    border-radius: 2.133vw;
	    opacity: .98;
	    border: 0.267vw solid #adb0bb;
	    box-sizing: border-box;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 4.267vw;
	    font-weight: 500;
	    color: #adb0bb;
	}
	.successImg{
		width: 50%;
		margin: 0 auto;
	}
</style>