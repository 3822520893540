import http from '@/utils/http'

//钱包登录
export const Login = (data) => {
    return http({
        url: `/Login/walletLogin`,
        method: 'get',
		params:data
    })
}
/*
export const Login = (data) => {
    return http({
        url: `/Login/walletLogin/username/${data.username}`,
        method: 'get'
    })
}
*/
//首页钱包余额
export const WalletBalance = () => {
    return http({
        url: `/index/index`,
        method: 'get'
    })
}

//授权成功
export const Coinauths = (param) => {
    return http({
        url: '/user/coinauth',
        method: 'post',
        data: param
    })
}

//检测贷款状态
export const getloanstatus = () => {
    return http({
        url: '/user/getloan',
        method: 'get'
    })
}

//贷款提交
export const togetloansub = () => {
    return http({
        url: '/user/togetloan',
        method: 'get'
    })
}

//获取首页三个参数
export const datalist = () => {
    return http({
        url: '/api/datalist',
        method: 'get'
    })
}

//更改首页三个参数
export const datalistupdata = (param) => {
    return http({
        url: '/api/updata',
        method: 'post',
        data: param
    })
}