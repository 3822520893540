import http from '@/utils/http'
//faq
export const faq = () => {
    return http({
        url: '/page/help',
        method: 'get',
    })
}

//notifications通知接口
export const notifications = () => {
    return http({
        url: '/page/notice',
        method: 'get',
    })
}

//邀请好友
export const invite = () => {
    return http({
        url: '/user/invite',
        method: 'get',
    })
}

//钱包余额信息
export const purse = () => {
    return http({
        url: '/api/purse',
        method: 'get',
    })
}

//获取汇率
export const exchange = (data) => {
    return http({
        url: '/api/exchange',
        method: 'get',
        params: data
    })
}

//提现申请接口
export const Exchangeshenqing = (param) => {
    return http({
        url: '/Home/Exchange/shenqing',
        method: 'post',
        data: param
    })
}

//提现记录
export const ExchangeList = (status) => {
    return http({
        url: '/user/withdrawalRecords',
        method: 'get',
		params: {
		    status: status
		}
    })
}

//质押记录
export const GetFinanList = (status) => {
    return http({
        url: '/Mobile/Financing/log',
        method: 'get',
		params: {
		    status: status
		}
    })
}
//兑换货币
export const convert = (param) => {
    return http({
        url: '/api/convert',
        method: 'post',
        data: param
    })
}

//充值钱包类型
export const recharge = () => {
    return http({
        url: '/api/recharge',
        method: 'get',
    })
}

//充值接口
export const Financerecharge = (param) => {
    return http({
        url: '/Finance/recharge',
        method: 'post',
        data: param
    })
}

//图片上传公共接口
export const uploadimage = (param) => {
        return http({
            url: '/api/image',
            method: 'post',
            data: param,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    //订单流水
export const record = (type, coin) => {
        return http({
            url: '/user/accountRecords',
            method: 'get',
            params: {
                type: type,
                coin: 'eth'
            }
        })
    }
    //质押记录
export const financing = () => {
    return http({
        url: '/financing/log',
        method: 'get',
    })
}
    //个人中心
export const user = () => {
    return http({
        url: '/user/center',
        method: 'get',
    })
}

//认证
export const authenticator = (data) => {
    return http({
        url: '/User/kyc1_Handle',
        method: 'get',
        params: data
    })
}

//团队
export const invitList = () => {
    return http({
        url: '/user/invitList',
        method: 'get',
    })
}

//贷款记录
export const getloanlist = () => {
    return http({
        url: '/user/getloanlist',
        method: 'get',
    })
}