<template>
  <router-view></router-view>
</template>

<script setup>
</script>

<style>
/*去掉底部导航栏顶部白边*/
.van-hairline--top-bottom:after {
  border: unset !important;
}

.el-popper {
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 999999999999 !important;
}

.el-select-dropdown {
  color: var(--el-color-primary);
  font-weight: 700;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 99999999999 !important;
}

.el-select-dropdown__item.selected {
  color: var(--el-color-primary);
  font-weight: 700;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 99999999999 !important;
}

.bg-emerald-700 {
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
  background: linear-gradient(146deg, #1F1C61 0%, #3C2061 60%, #262140 100%);
  border-radius: 218px 218px 218px 218px;
  opacity: 1;
  border: 1px solid rgba(153, 153, 153, 0.2);
}


</style>
