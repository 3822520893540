<template>
  <van-tabbar  class="tabbar" v-model="active" route>
    <van-tabbar-item replace to="/homeTab">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="../../static/nft/home/tab-home-selected.png" />
        <img class="image" v-else src="../../static/nft/home/tab-home.png">
		<p class="titlesx">{{$t('tabbar.Home')}}</p>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
		  <img class="image" v-if="props.active" src="../../static/nft/home/tab-home-selected.png" />
		  <img class="image" v-else src="../../static/nft/home/tab-home.png">
		  <div class="titlesx">{{$t('tabbar.Home')}}</div>
	  </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/tab1">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="../../static/nft/home/tab-art-selected.png" />
        <img class="image" v-else src="../../static/nft/home/tab-art.png">
		<p class="titlesx">{{$t('tabbar.cz')}}</p>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
		  <img class="image" v-if="props.active" src="../../static/nft/home/tab-art-selected.png" />
		  <img class="image" v-else src="../../static/nft/home/tab-art.png">
		  <p class="titlesx">{{$t('tabbar.cz')}}</p>
	  </template>
    </van-tabbar-item>
    <van-tabbar-item to="/tab4">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="../../static/nft/home/tab-mine-selected.png" />
        <img class="image" v-else src="../../static/nft/home/tab-mine.png">
		<p class="titlesx">{{$t('tabbar.Mine')}}</p>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
	  	<img class="image" v-if="props.active" src="../../static/nft/home/tab-mine-selected.png" />
	  	<img class="image" v-else src="../../static/nft/home/tab-mine.png">
		<p class="titlesx">{{$t('tabbar.Mine')}}</p>
	  </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script setup>
import {ref} from 'vue'

const active = ref(0);
const isBlack = localStorage.getItem('isBlack') ? localStorage.getItem('isBlack') : 'true'
//console.log(localStorage.getItem('isBlack'))
</script>

<style scoped lang="scss">
.tabbar{
  background: #191a23;
  .titlesx{
	  font-size: 12px;
	  text-align: center;
	  margin-bottom: 5px;
  }
  .image{
    width: 20px;
    height: 20px;
	margin: 0 auto;
	margin-top: 10px;
    margin-bottom: 3px;
  }
  .van-tabbar-item{
	  color: #888a90;
  }
  .van-tabbar-item--active{
    background-color: #191a23;
	color: #4D77FF!important;
  }
}

.tabbar-white{
	background: #ffffff;
}
</style>
