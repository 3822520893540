import { createI18n } from 'vue-i18n'

import us from './us'
import fr from './fr'
import ca from './ca'
import jp from './jp'
import kr from './kr'
import tw from './tw'
import au from './au'
import gb from './gb'
import kh from './kh'
import pt from './pt'
import my from './my'
import ae from './ae'
import de from './de'
import br from './br'
import vn from './vn'
import sg from './sg'
import tr from './tr'
import ru from './ru'
import ind from './in'
import kz from './kz'
import it from './it'
import th from './th'
import id from './id'
import ph from './ph'
import mn from './mn'
import no from './no'
import se from './se'
import pl from './pl'
import ly from './ly'
import ng from './ng'
import co from './co'
import pe from './pe'
import ar from './ar'
import mx from './mx'
import za from './za'
import sd from './sd'
import ma from './ma'
import cu from './cu'
import cn from './cn'

//let locale = sessionStorage.getItem('locale') || '';
let locale = localStorage.getItem('locale') || '';

if (locale == '') {
	locale = 'us';
    //sessionStorage.setItem('locale', locale);
	localStorage.setItem('locale', locale);
}

export const setupI18n = (app) => {
    const i18n = createI18n({
		globalInjection: true,
		legacy: false,
        locale: locale,
        messages: {
            us,
            fr,
            ca,
            jp,
            kr,
            tw,
            au,
            gb,
			kh,
            pt,
            my,
            ae,
            de,
            br,
            vn,
            sg,
            tr,
            ru,
            ind,
            kz,
            it,
            th,
            id,
            ph,
            mn,
            no,
            se,
            pl,
            ly,
            ng,
            co,
            pe,
            ar,
            mx,
            za,
            sd,
            ma,
            cu,
            cn
        }
    })
    app.use(i18n)
}
