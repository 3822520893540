<template>
  <div class="homePage">
	  
	<div class="tongbao" v-if="tbstatus">
		<img class="tbimg" src="../../static/nft/home/tongbao.png" />
		<p class="tbp">{{tbuser}}：{{tbedu}}ETH{{$t('tab1.tip1')}}</p>
	</div>
	  
    <div class="text-white p-4 min-h-screen">
		
		
		
		<div
		  class="mt-6 p-4 flex rounded-lg cursor-pointer bg-gradient-to-b to-[#0d2429] relative from-art1 l2-bg"
		  v-for="(item,index) in list"
		  @click="gotozhiya(index)"
		>
		  <div class="van-image" style="width: 80px; height: 80px">
		    <img
		      class="van-image__img"
		      :data-src="$configimgurlg + item.img"
		      :src="$configimgurlg + item.img"
		      lazy="loaded"
		    />
		  </div>
		  <div class="leading-7 ml-3">
		    <div class="text-lg font-medium">{{item.name}}</div>
		    <div class="etcmoney">{{item.min}} - {{item.max}} ETH</div>
		    <div class="text-gray-deep">
		      Click to enter<img
		        src="../../static/nft/right_icon.png"
		        style="width: 13px; height: 13px; margin-left: 4px"
		      />
		    </div>
		  </div>
		</div>
		
    </div>
    <div style="height: 50px"></div>
	<Daikuan />
    <Service />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onUnmounted } from "vue";
import { Login, WalletBalance, Coinauths } from "@/api/hometab";
import { getallcoinList,getgradelist } from "@/api/tab1";
import { user } from "@/api/tab5";
import { showToast, showConfirmDialog } from "vant";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import Service from "@/components/Service";
import Daikuan from '@/components/Daikuan'

const router = useRouter();
const { t, locale } = useI18n();
const walletaccount = ref("");
const ethprofit = ref(0);
const ethrevenue = ref(0);
const ethearnings = ref(0);
const list = ref([])
const tbstatus = ref(false)
const tbuser = ref("0xD1......04899");
const tbedu = ref(2);

getuserinfo();

function getuserinfo() {
  let username = sessionStorage.getItem("username") || "";
  if (username != "") {
    //console.log(new Array(username.length).join('*') + username.substr(-4))
    let xname = username.substr(-8);
    walletaccount.value = xname;
  }
  
  getgradelist().then((res) => {
	  res.data.info.forEach((item, index)=>{
	      list.value.push(item)
	  })
	  console.log(list)
    })
    .catch((error) => {
      console.log(error.message);
    });
	
}

function toNotifications() {
  router.push({
    path: "/notifiCations",
  });
}

function gotozhiya(type) {
  router.push({
    path: "/tab2",
    query: {
      type: type,
    },
  });
}
onUnmounted(() => {
    //销毁
	//clearInterval(tongbaos)
});
</script>

<style scoped lang="scss">
@import "../../static/nft/nft-coin.icu_assets_index-e8285ab1.css";
.font-medium {
  font-weight: 500;
  width: 55px;
  /* height: 32px; */
  font-size: 18px;
  font-family: Poppins, Poppins;
  font-weight: 700;
  color: #ffffff;
  /* line-height: 32px; */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}
.homePage {
  background: linear-gradient(200deg, #1e50ff 5%, #000 70%);
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  color: #ffffff;
  backdrop-filter: blur(240px);
}

.text-gray-deep {
  width: 114px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
}

.l1-bg {
  border-radius: 8px;
  opacity: 1;

  border-image: linear-gradient(
      138deg,
      rgba(82.36725300550461, 211.84180945158005, 255, 1),
      rgba(255, 255, 255, 0.7799999713897705),
      rgba(127.5, 30.00000011175871, 255, 0),
      rgba(30.00000011175871, 80.00000283122063, 255, 0)
    )
    1 1;
  clip-path: border-box;
  margin-top: 50px;
  background: url("../../static/nft/NFT3.png") no-repeat;
  background-size: 100% 100%;
}

.l2-bg {
  background: url("../../static/nft/NFT4.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 8px;

  border-image: linear-gradient(
      138deg,
      rgba(90.99889263510704, 82.36725300550461, 255, 1),
      rgba(255, 255, 255, 0.7799999713897705),
      rgba(127.5, 30.00000011175871, 255, 0),
      rgba(30.00000011175871, 80.00000283122063, 255, 0)
    )
    1 1;
  clip-path: border-box;
}

.from-art3 {
  clip-path: border-box;
  background: url("../../static/nft/NFT5.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;

  border-image: linear-gradient(
      138deg,
      rgba(96.75331145524979, 82.36725300550461, 255, 1),
      rgba(255, 255, 255, 0.7799999713897705),
      rgba(127.5, 30.00000011175871, 255, 0),
      rgba(30.00000011175871, 80.00000283122063, 255, 0)
    )
    1 1;
}

.from-art4 {
  clip-path: border-box;
  background: url("../../static/nft/NFT6.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;

  border-image: linear-gradient(
      138deg,
      rgba(255, 82.36725300550461, 237.73672074079514, 1),
      rgba(255, 255, 255, 0.7799999713897705),
      rgba(127.5, 30.00000011175871, 255, 0),
      rgba(30.00000011175871, 80.00000283122063, 255, 0)
    )
    1 1;
}

.from-art5 {
  clip-path: border-box;
  background: url("../../static/nft/NFT7.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;

  border-image: linear-gradient(
      138deg,
      rgba(255, 82.36725300550461, 154.29756045341492, 1),
      rgba(255, 255, 255, 0.7799999713897705),
      rgba(127.5, 30.00000011175871, 255, 0),
      rgba(30.00000011175871, 80.00000283122063, 255, 0)
    )
    1 1;
}
.etcmoney {
  /* width: 143px; */
  /* height: 18px; */
  font-size: 14px;
  font-family: Poppins, Poppins;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}
.tongbao{
	background: rgba(3, 23, 27, 0.7);
    position: absolute;
    top: 5%;
    z-index: 9999;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    left: 15%;
    border-radius: 25px;
}
.tbimg{
	width: 25px;
    margin-right: 10px;
}
.tbp{
	font-size: 13px;
    color: #ffffff;
}
</style>
