<template>
  <div class="container">
	  

	    <van-swipe
	      vertical
		  style="position: absolute;top: 5%;z-index: 9999;
    width: 84%;left: 8%;
    height: 50px;
    line-height: 50px;"
	      :autoplay="3000"
	      :touchable="false"
	      :show-indicators="false"
	    >
	      <van-swipe-item style="width:100%;height:50px;padding: 5px 10px;background: rgba(255, 255, 2555, 0.2);display: flex;align-items: center;justify-content: left;border-radius: 25px;" v-for="item in tbdatalist">
				<img class="tbimg" src="../../static/nft/home/tongbao.png" style="margin-right: 10px;" />
				<p class="tbp">{{item.tbuser}}：{{item.tbedu}}ETH{{$t('tab1.tip1')}}</p>
		  </van-swipe-item>
		  
	    </van-swipe>
	  
    <div class="pageHeader">
      <div class="wrapper">
        <div class="box">
          <div class="name">{{$t('home.cumulative_transaction_amount')}}</div>
          <div class="value-box">
            <div class="value">{{zengzhangone}}</div>
            <div class="unit">ETH</div>
          </div>
        </div>
        <div class="box">
          <div class="name">{{$t('home.cumulative_transaction_number')}}</div>
          <div class="value">
            {{zengzhangtwo}}+
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="box">
          <div class="name">{{$t('home.cumulative_charitable_donations_amount')}}</div>
          <div class="value-box">
            <div class="value">{{zengzhangtree}}</div>
            <div class="unit">ETH</div>
          </div>
        </div>
        <div class="box">
          <div class="name">{{$t('home.number_of_online')}}</div>
          <div class="value">
            {{zengzhangfour}}+
          </div>
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <div class="btn-buy" @click="toDetailsx()">{{$t('home.Buy_on_OpenSea')}}</div>
      <div class="btn-know" @click="totab2s()" >{{$t('home.Know_More')}}</div>
    </div>
    <div class="product-wrapper">
      <van-swipe class="my-swipe" @change="onChange" :autoplay="3000" indicator-color="white">
		
		<template v-if="wugedata.length > 0">
				<van-swipe-item v-for="(item, index) in wugedata" :key="item.id" @click="toDetails(item.data,item.type)">
				  <div class="product-box">
				    <div class="product">
				      <div class="top-info">
				        <van-image
							v-if="false"
				            round
				            width="24px"
				            height="24px"
				            :src="item.img"
				        />
				        <div v-if="false" class="name">{{item.data.author}} /</div>
				      </div>
				      <div class="product-name">{{item.data.name}}</div>
				      <div class="desc">// 02 EYVOLution</div>
					  <div><img :src="$configimgurlg + item.data.img" style="width: 70%;height: 180px;margin: 0 auto;margin-top: 20px;border-radius: 25px;" /></div>
				    </div>
				  </div>
				</van-swipe-item>
		</template>
		<template v-else>
				<van-swipe-item @click="toDetails(0,0)">
				  <div class="product-box">
				    <div class="product">
				      <div class="top-info">
				        <van-image
							v-if="false"
				            round
				            width="24px"
				            height="24px"
				            src="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"
				        />
				        <div v-if="false" class="name">None yet /</div>
				      </div>
				      <div class="product-name">None yet</div>
				      <div class="desc">// None yet</div>
				    </div>
				  </div>
				</van-swipe-item>
		</template>
        
		
		
        <template #indicator="{ active, total }">
          <div class="custom-indicator">
            <div class="dot" :class="active === item - 1 ? 'active': ''" v-for="item in total" :key="item"></div>
          </div>
        </template>
		
      </van-swipe>
    </div>

    <div class="Charitable-wrapper">
      <div class="title">{{$t('home.Charitable_donations')}}</div>
      <div class="text">{{$t('home.Over_the_years')}}</div>
	  

	    
		<div class="picture-wrapper">
				  
				  <div class="vue3-marquee mt-2" style="--duration: 40s; --delay: 0s; --direction: normal; --pauseOnHover: running; --pauseOnClick: running; --loops: infinite; --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0); --gradient-width: 200px; --min-width: 0%;">
				  	<div class="transparent-overlay"></div>
				  	<!---->
				  	<div class="marquee">
						<div class="van-image" style="display: flex;">
							<img src="../../static/nft/newhai/jpg/1.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/2.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/3.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/4.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/5.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/6.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/7.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/8.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/9.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/10.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/11.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/12.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/13.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/14.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/15.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/16.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/17.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/18.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/19.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/20.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						</div>
				  	</div>
				  	<div class="marquee">
						<div class="van-image" style="display: flex;">
							<img src="../../static/nft/newhai/jpg/1.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/2.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/3.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/4.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/5.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/6.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/7.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/8.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/9.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/10.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/11.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/12.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/13.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/14.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/15.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/16.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/17.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/18.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/19.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
							<img src="../../static/nft/newhai/jpg/20.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						</div>
				  	</div>
				  </div>
		  
		</div>
		
		


      
	  
	  
    </div>

    <div class="get-to-know-wrapper">
      <div class="title">{{$t('home.Get_To_Know_Us')}}</div>
      <div class="wrapper">
        <div class="video-wrapper">
			
			<video controls poster="../../static/nft/home/mp4fengmian.png">
			    <source src="../../static/nft/home/pmnew.mp4" type="video/mp4">
			</video>
			
        </div>
        <div class="magicn-box">
          <img src="../../static/nft/home/icon-magicn.png" width="33px" height="33px">
          <div class="magicn">MAGICN</div>
        </div>
      </div>
    </div>

    <div class="Hot-Bids-wrapper">
      <div class="tab-wrapper">
        <div class="tab" @click="tabSelected = 0">
          <img v-if="tabSelected === 0" src="../../static/nft/home/bt-all-selected.png" width="82px" height="41px">
          <img v-else src="../../static/nft/home/bt-all.png" width="82px" height="41px">
        </div>
        <div class="tab magin15" @click="tabSelected = 1">
          <img v-if="tabSelected === 1" src="../../static/nft/home/bt-solana-selected.png" width="154px" height="41px">
          <img v-else src="../../static/nft/home/bt-solana.png" width="154px" height="41px">
        </div>
        <div class="tab" @click="tabSelected = 2">
          <img v-if="tabSelected === 2" src="../../static/nft/home/bt-art-selected.png" width="86px" height="41px">
          <img v-else src="../../static/nft/home/bt-art.png" width="86px" height="41px">
        </div>
      </div>

      <div class="Successful-wrapper">
        <div class="title">{{$t('home.Successful_examples_of_auctions')}}</div>
        <div class="text">{{$t('home.I_have_witnessed_countless')}}</div>
        <div class="picture-wrapper">
			
			<div class="vue3-marquee mt-2" style="--duration: 60s; --delay: 0s; --direction: normal; --pauseOnHover: running; --pauseOnClick: running; --loops: infinite; --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0); --gradient-width: 200px; --min-width: 0%;">
				<div class="transparent-overlay"></div>
				<!---->
				<div class="marquee">
					<div class="van-image" style="display: flex;">
						<img src="../../static/nft/newpai/jpg/IMG_20240322_212948.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_112956.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113013.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113042.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113116.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113144.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113156.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113400.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113414.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113453.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113504.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113513.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113602.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113614.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113658.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122456.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122456.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122507.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122522.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122537.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
					</div>
				</div>
				<div class="marquee">
					<div class="van-image" style="display: flex;">
						<img src="../../static/nft/newpai/jpg/IMG_20240322_212948.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_112956.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113013.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113042.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113116.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113144.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113156.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113400.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113414.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113453.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113504.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113513.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113602.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113614.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_113658.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122456.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122456.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122507.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122522.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
						<img src="../../static/nft/newpai/jpg/IMG_20240323_122537.jpg" style="width:160px !important;height: 168px !important;border-radius: 15px;">
					</div>
				</div>
			</div>
			
        </div>
      </div>

      <div class="Auction-House" style="margin-top: 81px">
        <div class="title">{{$t('home.Auction_Housex')}}</div>

		<div class="vue3-marquee mt-2" style="--duration: 50s; --delay: 0s; --direction: normal; --pauseOnHover: running; --pauseOnClick: running; --loops: infinite; --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0); --gradient-width: 200px; --min-width: 0%;">
			<div class="transparent-overlay"></div>
			<!---->
			<div class="marquee">
				<div class="van-image" style="display: flex;">
					<img src="../../static/nft/new/trust.png" class="qianbaotupian">
					<img src="../../static/nft/new/xiaohuli.png" class="qianbaotupian" style="width: 250px;">
					<img src="../../static/nft/new/ledger-logo-long.svg" class="qianbaotupian">
					<img src="../../static/nft/new/imTokenLogo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/cdc_logo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/torus-logo-blue-1.svg" class="qianbaotupian">
					<img src="../../static/nft/new/Consumer_Wordmark.svg" class="qianbaotupian">
					<img src="../../static/nft/new/logo.03b9a69.png" class="qianbaotupian" style="width: 350px;" >
					<img src="../../static/nft/new/logo.c8002cfc.png" class="qianbaotupian" style="width: 120px;">
					<img src="../../static/nft/new/logo.05577e5.png" class="qianbaotupian" style="width: 200px;">
					<img src="../../static/nft/new/logo-light.svg" class="qianbaotupian">
					<img src="../../static/nft/new/coinhub.svg" class="qianbaotupian">
					<img src="../../static/nft/new/ouyi.png" class="qianbaotupian" style="width: 110px;">
					<img src="../../static/nft/new/solflare-logo-white.c53d256d.svg" class="qianbaotupian">
					<img src="../../static/nft/new/notumLogo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/logo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/walletconnect.svg" class="qianbaotupian">
					<img src="../../static/nft/new/new_logo_light.svg" class="qianbaotupian">
					<img src="../../static/nft/new/logo.9658e1c5.svg" class="qianbaotupian">
				</div>
			</div>
			<div class="marquee">
				<div class="van-image" style="display: flex;">
					<img src="../../static/nft/new/trust.png" class="qianbaotupian">
					<img src="../../static/nft/new/xiaohuli.png" class="qianbaotupian" style="width: 250px;">
					<img src="../../static/nft/new/ledger-logo-long.svg" class="qianbaotupian">
					<img src="../../static/nft/new/imTokenLogo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/cdc_logo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/torus-logo-blue-1.svg" class="qianbaotupian">
					<img src="../../static/nft/new/Consumer_Wordmark.svg" class="qianbaotupian">
					<img src="../../static/nft/new/logo.03b9a69.png" class="qianbaotupian" style="width: 350px;" >
					<img src="../../static/nft/new/logo.c8002cfc.png" class="qianbaotupian" style="width: 120px;">
					<img src="../../static/nft/new/logo.05577e5.png" class="qianbaotupian" style="width: 200px;">
					<img src="../../static/nft/new/logo-light.svg" class="qianbaotupian">
					<img src="../../static/nft/new/coinhub.svg" class="qianbaotupian">
					<img src="../../static/nft/new/ouyi.png" class="qianbaotupian" style="width: 110px;">
					<img src="../../static/nft/new/solflare-logo-white.c53d256d.svg" class="qianbaotupian">
					<img src="../../static/nft/new/notumLogo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/logo.svg" class="qianbaotupian">
					<img src="../../static/nft/new/walletconnect.svg" class="qianbaotupian">
					<img src="../../static/nft/new/new_logo_light.svg" class="qianbaotupian">
					<img src="../../static/nft/new/logo.9658e1c5.svg" class="qianbaotupian">
				</div>
			</div>
		</div>

      </div>
	  
      <div class="Auction-House" style="margin-top: 47px">
        <div class="title">{{$t('home.Auction_House')}}</div>
		
		<div class="vue3-marquee mt-2" style="--duration: 40s; --delay: 0s; --direction: normal; --pauseOnHover: running; --pauseOnClick: running; --loops: infinite; --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0); --gradient-width: 200px; --min-width: 0%;">
			<div class="transparent-overlay"></div>
			<!---->
			<div class="marquee">
				<div class="van-image" style="display: flex;">
					<img src="../../static/nft/new2/sothebys-logo-2.svg" class="qianbaotupian">
					<img src="../../static/nft/new2/jiashide.png" class="qianbaotupian" style="width:350px;" >
					<img src="../../static/nft/new2/nagel-logo-300x67.png" class="qianbaotupian">
					<img src="../../static/nft/new2/LogoBlack.svg" class="qianbaotupian">
					<img src="../../static/nft/new2/logo.png" class="qianbaotupian" style="width:250px;">
					<img src="../../static/nft/new2/china_guardian.a8d328de.svg" class="qianbaotupian" >
					<img src="../../static/nft/new2/bonhams.png" class="qianbaotupian" style="width:200px;" >
				</div>
			</div>
			<div class="marquee">
				<div class="van-image" style="display: flex;">
					<img src="../../static/nft/new2/sothebys-logo-2.svg" class="qianbaotupian">
					<img src="../../static/nft/new2/jiashide.png" class="qianbaotupian" style="width:350px;" >
					<img src="../../static/nft/new2/nagel-logo-300x67.png" class="qianbaotupian">
					<img src="../../static/nft/new2/LogoBlack.svg" class="qianbaotupian">
					<img src="../../static/nft/new2/logo.png" class="qianbaotupian" style="width:250px;">
					<img src="../../static/nft/new2/china_guardian.a8d328de.svg" class="qianbaotupian" >
					<img src="../../static/nft/new2/bonhams.png" class="qianbaotupian" style="width:200px;" >
				</div>
			</div>
		</div>
		
      </div>
	  
	  <div style="height:50px"></div>

		
		<section class="subscribe">
			<div class="link-view">
				<div class="link-item" @click="toFaq">
					<div class="label"> {{$t('hometab.text35')}} </div>
				</div>
				<div class="link-item" @click="torichText(1)">
					<div class="label"> {{$t('hometab.text36')}} </div>
				</div>
				<div class="link-item" @click="torichText(2)">
					<div class="label"> {{$t('hometab.text37')}} </div>
				</div>
				<div class="link-item" @click="torichText(3)">
					<div class="label"> {{$t('hometab.text38')}} </div>
				</div>
				<div class="link-item" @click="torichText(4)">
					<div class="label"> {{$t('hometab.text39')}} </div>
				</div>
			</div>
		</section>

		
		<div style="height:50px"></div>

    </div>
	
	
	<Daikuan />
	<Service />
  </div>
</template>

<script setup>
import {ref,getCurrentInstance,onMounted,onUnmounted,reactive} from "vue";
import { Login,WalletBalance,Coinauths,getloanstatus,togetloansub,datalist,datalistupdata } from '@/api/hometab'
import { getallcoinList } from '@/api/tab1'
import { showToast,showConfirmDialog } from 'vant';
import Web3 from 'web3'
import {useI18n} from "vue-i18n";
import { useRouter } from "vue-router";
import Service from '@/components/Service'
import Daikuan from '@/components/Daikuan'
import {financinlist} from '@/api/tab2'

import tximg1 from '../../static/nft/home/cat.jpeg'
import tximg2 from '../../static/nft/home/qe.png'
import tximg3 from '../../static/nft/home/qw.png'
import tximg4 from '../../static/nft/home/ta.png'
import tximg5 from '../../static/nft/home/qt.png'
import { number } from "echarts/lib/export";



const router = useRouter()
const {t,locale} = useI18n()
const accountallmoney = ref('0.00')
const walletaccount = ref('')
const ethprofit = ref(0)
const ethrevenue = ref(0)

const tabSelected = ref(0)
const accept = ref(false)
const bidsListLeft = ref([])
const bidsListRight = ref([])

//const zengzhangone = ref(256436)
const zengzhangone = ref(0)
const zengzhangoneting = ref(0)
const xone = ref(0)
const tone = ref(0)
const onesuijitingzhishuzi = ref(Math.floor(Math.random() * (150 - 100) ) + 100)
const onesuijitingmiaoshu = ref(Math.floor(Math.random() * (51 - 31) ) + 31)
//const zengzhangtwo = ref(11132)
const zengzhangtwo = ref(0)
const zengzhangtwoting = ref(0)
const xtwo = ref(0)
const ttwo = ref(0)
const twosuijitingzhishuzi = ref(Math.floor(Math.random() * (21 - 11) ) + 11)
const twosuijitingmiaoshu = ref(Math.floor(Math.random() * (51 - 31) ) + 31)
//const zengzhangtree = ref(2465)
const zengzhangtree = ref(0)
const zengzhangtreeting = ref(0)
const xtree = ref(0)
const ttree = ref(0)
const treesuijitingzhishuzi = ref(Math.floor(Math.random() * (50 - 10) ) + 10)
const treesuijitingmiaoshu = ref(Math.floor(Math.random() * (51 - 31) ) + 31)
//const zengzhangfour = ref(9900)
const zengzhangfour = ref(Math.floor(Math.random() * (20000 - 10000) ) + 10000)
const zengzhangfourting = ref(zengzhangfour.value + 100)
const xfour = ref(0)
const tfour = ref(0)
const foursuijitingzhishuzi = ref(Math.floor(Math.random() * (10 - 1) ) + 1)
const foursuijitingmiaoshu = ref(Math.floor(Math.random() * (51 - 31) ) + 31)

const wugedata = ref([])
/*
const dkstatus = ref(false)
const dkinfo = ref([])
const dkkstatus = ref(false)
const dktkstatus = ref(false)
*/

const tbdatalist = ref([])
getsuijitbdata()
function getsuijitbdata(){
	for(let i=0;i<10;i++){
		let newarray = {tbuser:'',tbedu:''}
		newarray.tbuser = '0x' + generateRandomID(2) + '......' + generateRandomID(5)
		newarray.tbedu = Math.floor(Math.random() * (188 - 2) ) + 2
		tbdatalist.value.push(newarray)
	}
	
	setTimeout(()=>{
		getsuijitbdata()
	}, 30000)
}

datalist().then(res=>{
	//console.log(Number(res.data.info.num1))
	zengzhangone.value = Number(res.data.info.num1)
	zengzhangoneting.value = zengzhangone.value + 100
	zengzhangtwo.value = Number(res.data.info.num2)
	zengzhangtwoting.value = zengzhangtwo.value + 100
	zengzhangtree.value = Number(res.data.info.num3)
	zengzhangtreeting.value = zengzhangtree.value + 100
}).catch((error) => {
	console.log(error)
});


const djsone = setInterval(()=>{
		if(zengzhangone.value != 0){
			zengzhangone.value += 1
			//if(zengzhangone.value == 256536){
			if(zengzhangone.value == zengzhangoneting.value){
				clearInterval(djsone)
				// setTimeout(()=>{
				// 	djsonefunction()
				// },2000);
				djsonefunction()
			}
		}
		
},50);
const djsones = ref('')
function djsonefunction (){
	/*
	djsones.value = setInterval(()=>{
			xone.value += 1
			if(xone.value >= onesuijitingzhishuzi.value){
				tone.value += 1
				if(tone.value == onesuijitingmiaoshu.value){
					xone.value = 0
					tone.value = 0
					onesuijitingzhishuzi.value = Math.floor(Math.random() * (150 - 100) ) + 100
					onesuijitingmiaoshu.value = Math.floor(Math.random() * (51 - 31) ) + 31
				}
			} else {
				zengzhangone.value += 1
			}
			
	},50);
	*/
   djsones.value = setInterval(()=>{
   			zengzhangone.value += 1
			//console.log('3分钟')
			/*
			datalistupdata({num1:(zengzhangoneting.value + 1)}).then(res=>{
				console.log('num1',res.data)
			})
			*/
   },180000);
}
const djstwo = setInterval(()=>{
		if(zengzhangtwo.value != 0){
			zengzhangtwo.value += 1
			//if(zengzhangtwo.value == 11232){
			if(zengzhangtwo.value == zengzhangtwoting.value){
				clearInterval(djstwo)
				// setTimeout(()=>{
				// 	djstwofunction()
				// },2000);
				djstwofunction()
			}
		}
		
},50);

const djstwos = ref('')
function djstwofunction (){
	/*
	djstwos.value = setInterval(()=>{
			xtwo.value += 1
			if(xtwo.value >= twosuijitingzhishuzi.value){
				ttwo.value += 1
				if(ttwo.value == twosuijitingmiaoshu.value){
					xtwo.value = 0
					ttwo.value = 0
					twosuijitingzhishuzi.value = Math.floor(Math.random() * (21 - 11) ) + 11
					twosuijitingmiaoshu.value = Math.floor(Math.random() * (51 - 31) ) + 31
				}
			} else {
				zengzhangtwo.value += 1
			}
			
	},50);
	*/
   djstwos.value = setInterval(()=>{
	   //console.log('2分钟')
   		zengzhangtwo.value += 1
		/*
		datalistupdata({num2:(zengzhangtwoting.value + 1)}).then(res=>{
			console.log('num2',res.data)
		})
		*/
   },120000);
}

const djstree = setInterval(()=>{
		if(zengzhangtree.value != 0){
			zengzhangtree.value += 1
			
			//if(zengzhangtree.value == 2565){
			if(zengzhangtree.value == zengzhangtreeting.value){
				clearInterval(djstree)
				// setTimeout(()=>{
				// 	djstreefunction()
				// },2000);
				djstreefunction()
			}
		}
		
},50);
const djstrees = ref('')
function djstreefunction (){
	/*
	djstrees.value = setInterval(()=>{
			xtree.value += 1
			if(xtree.value >= treesuijitingzhishuzi.value){
				ttree.value += 1
				if(ttree.value == treesuijitingmiaoshu.value){
					xtree.value = 0
					ttree.value = 0
					treesuijitingzhishuzi.value = Math.floor(Math.random() * (50 - 10) ) + 10
					treesuijitingmiaoshu.value = Math.floor(Math.random() * (51 - 31) ) + 31
				}
			} else {
				zengzhangtree.value += 1
			}
			
	},50);
	*/
   djstrees.value = setInterval(()=>{
   		//console.log('60分钟')
   		zengzhangtree.value += 1
		/*
		datalistupdata({num3:(zengzhangtreeting.value + 1)}).then(res=>{
			console.log('num3',res.data)
		})
		*/
   },3600000);
}
const djsfour = setInterval(()=>{
		zengzhangfour.value += 1
		
		//if(zengzhangfour.value == 10000){
		if(zengzhangfour.value==zengzhangfourting.value){
			clearInterval(djsfour)
			// setTimeout(()=>{
			// 	djsfourfunction()
			// },2000);
			djsfourfunction()
		}
},50);
const djsfours = ref('')
let renshutime  = ref(1)
function djsfourfunction (){
	/*
	djsfours.value = setInterval(()=>{
			xfour.value += 1
			if(xfour.value >= foursuijitingzhishuzi.value){
				tfour.value += 1
				if(tfour.value == foursuijitingmiaoshu.value){
					xfour.value = 0
					tfour.value = 0
					if(renshutime.value==1){
						foursuijitingzhishuzi.value = Math.floor(Math.random() * (10 - 1) ) + 1
						foursuijitingmiaoshu.value = Math.floor(Math.random() * (51 - 31) ) + 31
					} else {
						foursuijitingzhishuzi.value = Math.floor(Math.random() * (100 - 50) ) + 50
						foursuijitingmiaoshu.value = Math.floor(Math.random() * (51 - 31) ) + 31
					}
				}
			} else {
				zengzhangfour.value += 1
			}
			
	},50);
	*/
   djsfours.value = setInterval(()=>{
		//zengzhangfour.value = Math.floor(Math.random() * (20000 - 10000) ) + 10000
		zengzhangfour.value = Math.floor(Math.random() * (zengzhangfourting.value - (zengzhangfour.value-50)) ) + (zengzhangfour.value-50)
   },1000);
}

const fangjiantype = ref(0)

onMounted(() => {
  financinlist({type:0,status:0}).then(res=>{
    if (res.data.length>0) {
		wugedata.value.push({data:res.data[0],type:0,img:tximg1})
    }
  })
  financinlist({type:1,status:0}).then(res=>{
    if (res.data.length>0) {
		wugedata.value.push({data:res.data[0],type:1,img:tximg2})
    }
  })
  financinlist({type:2,status:0}).then(res=>{
    if (res.data.length>0) {
		wugedata.value.push({data:res.data[0],type:2,img:tximg3})
    }
  })
  financinlist({type:3,status:0}).then(res=>{
    if (res.data.length>0) {
		wugedata.value.push({data:res.data[0],type:3,img:tximg4})
    }
  })
  financinlist({type:4,status:0}).then(res=>{
    if (res.data.length>0) {
		wugedata.value.push({data:res.data[0],type:4,img:tximg5})
    }
  })
});
getuserinfo()
gettimetip()
function gettimetip(){
		const date = new Date()
		const hour = date.getHours();
		//console.log("现在时间是:"+hour+"点!")
		if (hour >= 0 && hour < 12) {
			//console.log('上午好')
			renshutime.value = 1
		}
		else if(hour===12){
			//console.log('中午好')
			renshutime.value = 2
		}
		else if(hour  > 12 && hour < 18) {
			//console.log('下午好')
			renshutime.value = 2
		} else {
			//console.log('晚上好')
			renshutime.value = 2
		}
}
function generateRandomID(nums){
	const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const idLength = Number(nums); // 字符串长度
	let randomID = '';
 
	for (let i = 0; i < idLength; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		randomID += characters.charAt(randomIndex);
	}
 
	return randomID;
}
function getuserinfo(){
	//let username = sessionStorage.getItem('username') || '';
	let username = localStorage.getItem('username') || '';
	if(username != ''){
		let xname = username.substr(-8)
		walletaccount.value = xname
	} else {
		showConfirmDialog({
		  title: t('tab4.Prompt'),
		  message:
		    t('hometab.top'),
		  confirmButtonText:t('tab4.Confirm'),
		  cancelButtonText:t('tab4.Cancel'),
		})
		  .then(() => {
				localStorage.clear();
				router.push({
				    path: "/empty_wallet"
				});
		  })
		  .catch(() => {
		    // on cancel
		  });
		return false
	}
	WalletBalance().then(res => {
		//console.log(res)
		accountallmoney.value = res.data.url.revenue
		ethprofit.value = res.data.url.user_revenue
		ethrevenue.value = res.data.url.participant
	}).catch((error) => {
		console.log(error.message)
	})
	/*
	getloanstatus().then(res => {
		//console.log(res.data)
		if(res.data.info != null && res.data.info != ''){
			dkstatus.value = true
			dkinfo.value = res.data.info
		}
		//console.log(dkstatus.value)
		//console.log(dkinfo.value)
	}).catch((error) => {
		console.log(error.message)
	})
	*/
}
/*
function gotodaikuan(){
	dkkstatus.value = true
}
function gotodaikuantiaokuang(){
	dktkstatus.value = true
	dkkstatus.value = false
}
function guanbidkgz(){
	dktkstatus.value = false
}
function daikuansub(){
	 togetloansub().then(res => {
	 	//console.log(res.data)
		dktkstatus.value = false
	 }).catch((error) => {
	 	console.log(error.message)
		showToast(error.message)
	 })
}
*/
function toNotifications(){
	router.push({
	    path: "/notifiCations"
	});
}
function totab2(){
	router.push({
	    path: "/tab1"
	});
}
function toDetail(item){
  router.push({
    path: "/hashmask",
    query: {
      id: item.id,
	  type:2
    }
  });
}
function toDetailsx(){
	if(wugedata.length > 0){
		router.push({
		   path: "/hashmask",
		   query: {
		     id: wugedata.value[fangjiantype.value].data.id,
		 	type:wugedata.value[fangjiantype.value].type
		   }
		 });
	} else {
		router.push({
		  path: "/tab1"
		});
	}
	
}
function toDetails(item,type){
	if(item==0 && type==0){
		router.push({
		  path: "/tab1"
		});
	} else {
		router.push({
		  path: "/hashmask",
		  query: {
		    id: item.id,
			  type:type
		  }
		});
	}
	
}
function totab2s(){
	if(wugedata.length > 0){
		router.push({
		  path: "/tab2",
		  query: {
			type:wugedata.value[fangjiantype.value].type
		  }
		});
	} else {
		router.push({
		  path: "/tab1"
		});
	}
}
function onChange(index){
	fangjiantype.value = index
}
function toFaq(){
	router.push({
	  path: "/faq"
	});
}
function torichText(type){
	router.push({
	  path: "/richText",
	  query: {
		type:type
	  }
	});
}
onUnmounted(() => {
    //销毁
	clearInterval(djsone)
	clearInterval(djstwo)
	clearInterval(djstree)
	clearInterval(djsfour)
	//clearInterval(tongbaos)
	
	clearInterval(djsones.value)
	clearInterval(djstwos.value)
	clearInterval(djstrees.value)
	clearInterval(djsfours.value)
	
});
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background-image: url("../../static/nft/home/home-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-attachment: local;
  overflow: scroll;
}
.pageHeader{
  margin: 139px 17px 0px 18px;
  height: 232px;
  background-image: url("../../static/nft/home/top-bg1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .wrapper{
    display: flex;
    height: 116px;
    .box{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .name{
        margin-bottom: 6px;
        text-align: center;
        font-size: 12px;
        font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
        font-weight: 500;
        color: #A2C7FF;
        line-height: 16px;
      }
      .value{
        font-size: 30px;
        font-family: Swis721 Cn BT, Swis721 Cn BT;
        line-height: 33px;
        background: linear-gradient(180deg, #FFFFFF, rgba(255,255,255,0.24));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .unit{
        font-size: 15px;
        font-family: Swis721 Cn BT, Swis721 Cn BT;
        background: linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,0.24));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .value-box{
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
.btn-wrapper{
  position: absolute;
  top: 747px;
  left: 17px;
  right: 16px;
  height: 258px;
  border-radius: 8.5px;
  border: 2px solid #1E50FF;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .btn-buy{
    height: 50px;
    margin: 0 16px;
    background: #1E50FF;
    border-radius: 8.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Poppins, Poppins;
    font-weight: 500;
    color: #FFFFFF;
  }
  .btn-know{
    height: 50px;
    margin: 16px;
    background: transparent;
    border: 1px solid white;
    border-radius: 8.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Poppins, Poppins;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.product-wrapper{
  margin-top: 133px;
  .product-box{
    display: flex;
    justify-content: center;
    .product{
      width: 245px;
      height: 322px;
      background-image: url("../../static/nft/home/product-border-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .top-info{
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 10px;
        .name{
          margin-left: 5px;
          font-size: 10px;
          font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
          font-weight: 400;
          line-height: 12px;
          background: linear-gradient(112deg, #28BAE3 0%, #2A43E3 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .img-wrapper{
        margin-top: 16px;
        display: flex;
        justify-content: center;
      }
      .product-name{
        margin-top: 5px;
        margin-left: 20px;
        font-size: 15px;
        font-family: Poppins, Poppins;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 23px;
      }
      .desc{
        margin-top: 3px;
        margin-left: 20px;
        font-size: 10px;
        font-family: Poppins, Poppins;
        font-weight: 500;
        color: #8368F1;
        line-height: 15px;
      }
    }
  }
  .custom-indicator{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    .dot{
      width: 6.5px;
      height: 6.5px;
      border-radius: 50%;
      background: rgba(255, 255, 255, .3);
      margin-left: 16px;
    }
    .active{
      background: #FFFFFF
    }
  }
}
.Charitable-wrapper{
  margin-top: 190px;
  .title{
    margin-left: 13px;
    font-size: 25px;
    font-family: Poppins, Poppins;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 38px;
  }
  .text{
    margin: 38px 22px 25px 19px;
    font-size: 15px;
    font-family: Poppins, Poppins;
    font-weight: 400;
    color: #EBEBEB;
    line-height: 19px;
  }
  .picture-wrapper{
    display: flex;
    margin-left: 5px;
    padding: 10px;
    overflow-x: scroll;
    background: #12194E;
    border-radius: 8.5px;
    img{
      margin-right: 8px;
    }
  }
}
.get-to-know-wrapper{
  height: 265px;
  margin: 56px 16px 0px 16px;
  .title{
    font-size: 25px;
    font-family: Poppins, Poppins;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 38px;
  }
  .wrapper{
    margin-top: 14px;
    border: 1px solid #1E50FF;
    border-radius: 8.5px;
    .video-wrapper{
      margin: 17px 16px 20px 16px;
      border-radius: 8.5px;
      overflow: hidden;
    }
    .magicn-box{
      margin-left: 16px;
      margin-bottom: 17px;
      display: flex;
      align-items: center;
      .magicn{
        margin-left: 8px;
        font-size: 17px;
        font-family: Poppins, Poppins;
        font-weight: 700;
        color: #FFFFFF;
        line-height: 33px;
      }
    }
  }

}
.Hot-Bids-wrapper{
  //margin-top: 152px;
  margin-top: 0px;
  .title-wrapper{
    margin: 0px 24px 18px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
      font-size: 25px;
      font-family: Poppins, Poppins;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 24px;
    }
    .all{
      font-size: 13px;
      font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
      font-weight: 400;
      color: #354BFF;
      line-height: 19px;
    }
  }
  .tab-wrapper{
    margin: 0 3px 47px 13px;
    display: flex;
    .magin15{
      margin: 0 15px;
    }
  }
  .list-wrapper{
    display: flex;
    .left{
      flex: 1;
      margin-left: 17px;
      margin-right: 4px;
    }
    .right{
      flex: 1;
      margin-left: 4px;
      margin-right: 16px;
    }
    .product-box{
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      .product{
        width: 100%;
        background-image: url("../../static/nft/home/product-border-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .top-info{
          display: flex;
          align-items: center;
          margin-left: 15px;
          margin-top: 7px;
          .name{
            margin-left: 4px;
            font-size: 7px;
            font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
            font-weight: 400;
            line-height: 8px;
            background: linear-gradient(112deg, #28BAE3 0%, #2A43E3 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .img-wrapper{
          margin-top: 9px;
          display: flex;
          justify-content: center;
          border-radius: 7px;
          overflow: hidden;
        }
        .product-name{
          margin-top: 3px;
          margin-left: 15px;
          font-size: 11px;
          font-family: Poppins, Poppins;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 16px;
        }
        .desc{
          margin-top: 3px;
          margin-left: 15px;
          font-size: 7px;
          font-family: Poppins, Poppins;
          font-weight: 500;
          color: #8368F1;
          line-height: 11px;
        }
      }
    }
  }
}
.Successful-wrapper{
  margin-top: 75px;
  .title{
    margin-left: 17px;
    margin-right: 11px;
    font-size: 25px;
    font-family: Poppins, Poppins;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 38px;
  }
  .text{
    margin: 9px 24px 8px 17px;
    font-size: 15px;
    font-family: Poppins, Poppins;
    font-weight: 400;
    color: #EBEBEB;
    line-height: 19px;
  }
  .picture-wrapper{
    display: flex;
    margin-left: 17px;
    overflow-x: scroll;
    img{
      margin-right: 13px;
      border-radius: 12.5px;
    }
  }
}
.Auction-House{
  .title{
    padding-left: 24px;
    font-size: 25px;
    font-family: Poppins, Poppins;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 38px;
  }
  .img-wrapper{
    overflow-x: scroll;
    display: flex;
  }
}
.magicn-wrapper{
  margin-top: 82px;
  .magicn-box{
    margin-left: 22px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .magicn{
      margin-left: 9px;
      font-size: 21px;
      font-family: Poppins, Poppins;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 33px;
    }
  }
  .magicn1{
    margin-left: 25px;
    font-size: 21px;
    font-family: Inter, Inter;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 33px;
  }
  .item-wrapper{
    display: flex;
    margin-left: 28px;
    .left{
      flex: 1;
    }
    .right{
      flex: 1.5;
    }
    .item{
      font-size: 14px;
      font-weight: 400;
      color: #93989A;
      line-height: 24px;
      cursor: pointer;
    }
  }
}
.bottom-wrapper{
  margin-top: 47px;
  margin-bottom: 100px;
  .line{
    border-bottom: 1px solid #1E50FF;
  }
  .copy-right{
    margin-left: 25px;
    margin-top: 25px;
    font-size: 15px;
    font-family: Inter, Inter;
    font-weight: 500;
    color: #A6AEAD;
    line-height: 21px;
  }
  .cookies-wrapper{
    margin: 17px 8px 0px 25px;
    font-size: 15px;
    font-family: Inter, Inter;
    font-weight: 500;
    color: #A6AEAD;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn{
      width: 96px;
      height: 41px;
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      font-size: 13px;
      font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.vue3-marquee {
	    overflow-x: hidden!important;
	    display: flex!important;
	    flex-direction: row!important;
	    position: relative;
	    width: 100%;
	}
	.mt-2 {
	    margin-top: 0.5rem;
	}
	.vue3-marquee>.transparent-overlay {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	}
	.vue3-marquee>.marquee {
	    flex: 0 0 auto;
	    min-width: var(--min-width);
	    z-index: 1;
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    animation: scroll var(--duration) linear var(--delay) var(--loops);
	    animation-direction: var(--direction);
	}
	.van-image {
	    position: relative;
	    display: inline-block;
	}
	.van-image__img, .van-image__error, .van-image__loading {
	    display: block;
	    width: 100%;
	    height: 100%;
	}
	.vue3-marquee {
		overflow-x:hidden!important;
		display:flex!important;
		flex-direction:row!important;
		position:relative;
		width:100%
	}
	.vue3-marquee:hover div {
		animation-play-state:var(--pauseOnHover)
	}
	.vue3-marquee:active div {
		animation-play-state:var(--pauseOnClick)
	}
	.vue3-marquee>.marquee {
		flex:0 0 auto;
		min-width:var(--min-width);
		z-index:1;
		display:flex;
		flex-direction:row;
		align-items:center;
		animation:scroll var(--duration) linear var(--delay) var(--loops);
		animation-direction:var(--direction)
	}
	@keyframes scroll {
		0% {
		transform:translate(0)
	}
	to {
		transform:translate(-100%)
	}
	}.vue3-marquee>.overlay {
		position:absolute;
		width:100%;
		height:100%
	}
	.vue3-marquee>.transparent-overlay {
		position:absolute;
		width:100%;
		height:100%
	}
	.vue3-marquee>.overlay:before,.vue3-marquee>.overlay:after {
		background:linear-gradient(to right,var(--gradient-color));
		content:"";
		height:100%;
		position:absolute;
		width:var(--gradient-width);
		z-index:2
	}
	.vue3-marquee>.overlay:after {
		right:0;
		top:0;
		transform:rotate(180deg)
	}
	.vue3-marquee>.overlay:before {
		left:0;
		top:0
	}
	.daikuans{
		position: fixed;
		bottom: 140px;
		right: 10px;
		z-index: 50;
		width: 60px;
	}
	
	
	.van-dialog{
		//background: transparent !important;
		//background: var(--van-dialog-background) !important;
	}
	
	.dialog-wrap.bgColor{
		background-color: #fff
	}
	
	.dialog-wrap .dialog-head{
		height: 16.267vw;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border: .267vw solid #f4f5f7;
		box-sizing: border-box
	}
	
	.dialog-wrap .dialog-head .title{
		font-size: 4vw;
		font-weight: 500;
		color: #27282c
	}
	
	.dialog-wrap .dialog-head .close-icon{
		font-size: 5.333vw;
		color: #a7aaae;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 4.8vw
	}
	
	.golden {
		background: linear-gradient(105deg, #facd6f, #ffe8ba 41%, #fcd27e);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent
	}
	
	.dialog-content {
		position: relative;
		height: calc(100% + 10.667vw)
	}
	
	.dialog-content .bg {
		width: 100%;
		display: block
	}
	
	.dialog-content .dialog-main {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0
	}
	
	.dialog-content .dialog-main .tag {
		width: 21.333vw;
		position: relative;
		top: 2%
	}
	
	.dialog-content .dialog-main .tag .tag-bg {
		width: 100%;
		display: block
	}
	
	.dialog-content .dialog-main .tag .tag-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 3.0vw;
		font-weight: 500;
		color: #362977;
		display: flex;
		align-items: center;
		padding-left: 3.733vw;
		box-sizing: border-box
	}
	
	.dialog-content .dialog-main .text-content {
		padding: 7.733vw 8.533vw 0;
		box-sizing: border-box;
		color: #2b1773;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center
	}
	
	.dialog-content .dialog-main .text-content .title {
		font-size: 7.267vw;
		font-weight: 600;
		line-height: 10.667vw
	}
	
	.dialog-content .dialog-main .text-content .text {
		font-size: 2.733vw;
		font-weight: 400;
		line-height: 4.267vw
	}
	
	.dialog-content .dialog-main .button-view {
		width: calc(100% - 13.067vw);
		height: 21.333vw;
		position: absolute;
		left: 6.533vw;
		top: 57.467vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between
	}
	
	.dialog-content .dialog-main .button-view .button-item {
		display: flex;
		justify-content: space-between;
		background: #261e18;
		border-radius: 2.133vw
	}
	
	.dialog-content .dialog-main .button-view .button-item .button-box {
		height: 9.333vw;
		background: linear-gradient(90deg, #62ecb7, #a3f4d5 22%, #68edb9 47%, #a4f4d6 69%, #62ecb7);
		border-radius: 2.133vw;
		box-sizing: border-box;
		padding: 0 5.067vw;
		font-size: 3.533vw;
		font-weight: 500;
		color: #362977;
		display: flex;
		align-items: center
	}
	
	.dialog-content .dialog-main .button-view .button-item .tip {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 2.667vw
	}
	
	.dialog-content .dialog-main .info-view {
		width: calc(100% - 13.067vw);
		height: 33.333vw;
		position: absolute;
		top: 78.8vw;
		left: 6.533vw;
		background-color: rgba(0, 0, 0, .12);
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		padding: 0 7.467vw;
		background-color: #9ea8c0
	}
	
	.dialog-content .dialog-main .info-view .tip {
		font-size: 3.267vw;
		font-weight: 500;
		color: #4d11b6;
		text-align: center;
		margin-bottom: 1.6vw
	}
	
	.dialog-content .dialog-main .info-view .line {
		width: calc(100% - 8vw);
		margin: 1.6vw auto;
		border: .267vw solid #342174;
		height: 0
	}
	
	.dialog-content .dialog-main .info-view .tr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 8vw);
		margin: 0 auto;
		font-size: 2.733vw;
		color: #4d11b6
	}
	
	.dialog-content .dialog-main .info-view .tr .th {
		font-weight: 500
	}
	
	.dialog-content .dialog-main .info-view .tr .td {
		font-weight: 700
	}
	
	.dialog-content .button-yes {
		position: relative
	}
	
	.dialog-content .button-yes .link-btn {
		width: 100%;
		display: block
	}
	
	.dialog-content .button-yes .button-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 4.067vw;
		font-weight: 600;
		color: #fff
	}
	
	.dialog-content .button-yes .button-box .link-icon {
		width: 4.267vw;
		margin-left: 2.133vw;
		display: block
	}
	
	
	.button {
		width: 100%;
		height: 11.733vw;
		border-radius: 2.133vw;
		opacity: .98;
		border: .267vw solid #adb0bb;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 4.067vw;
		font-weight: 500;
		color: #adb0bb
	}
	
	.button.is-disabled {
		pointer-events: none
	}
	
	.button.sincere {
		background-color: #253340;
		border-color: #253340;
		color: #fff
	}
	
	.button.white {
		background-color: #fff;
		border-color: #fff;
		color: #1a1b1c
	}
	
	.button.disabled .button.info {
		background-color: #cbcbcb;
		border-color: #cbcbcb;
		color: #fff
	}
	
	.button.disabled {
		pointer-events: none
	}
	
	.rule-content {
	    padding: 4vw;
	    box-sizing: border-box;
	}
	.rule-content .rule-main{
	    height: 40vh;
	    overflow-y: auto;
	    margin-bottom: 4vw;
	}
	.button.sincere {
	    background-color: #253340;
	    border-color: #253340;
	    color: #fff;
	}
	.button {
	    width: 100%;
	    height: 11.733vw;
	    border-radius: 2.133vw;
	    opacity: .98;
	    border: 0.267vw solid #adb0bb;
	    box-sizing: border-box;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 4.267vw;
	    font-weight: 500;
	    color: #adb0bb;
	}
	section {
	    padding: 6.4vw 4vw;
	    box-sizing: border-box;
	}
	section.partners {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	}
	section.partners .partners-item {
	    width: calc(50% - 2.667vw);
		margin-top: 4vw;
	}
	section.partners .partners-item img {
	    width: 100%;
	    display: block;
	}
	section.subscribe .link-view .link-item {
	    height: 10.667vw;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	section.subscribe .link-view .link-item .label {
	    font-size: 3.733vw;
	    font-weight: 500;
	    color: #fff;
	}
	.tongbao{

	}
	.tbimg{
		width: 25px;
	}
	.tbp{
		font-size: 13px;
	    color: #ffffff;
		height: 50px;
		line-height: 50px;
		overflow: hidden;
	}
	.qianbaotupian{
		width: 160px;
		height: 50px;
		margin: 0 20px;
	}
</style>
