<template>
    <div class="app-main">

		<div class="van-nav-bar__placeholder" style="height: 46px;">
			<div class="van-nav-bar van-nav-bar--fixed van-safe-area-top">
				<div class="van-nav-bar__content" style="background: none">
					<div @click="goToOneTab1" class="van-nav-bar__left van-haptics-feedback">
<!--						<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>-->
						<img src="../../static/nft/top_back.png"  style="width: 34px;height: 34px;"/>
					</div>
					<div class="van-nav-bar__title van-ellipsis">Antique</div>
					<div class="van-nav-bar__right van-haptics-feedback"></div>
				</div>
			</div>
			<div class="changemethd" @click="goToOneTab2" style="position: absolute; top: 13px; right: 7%;z-index: 999;" >
                <img src="../../static/nft/change.png"  alt="">
            </div>
		</div>

		<div class="oops">
		   <van-tabs v-model:active="active" title-active-color="#FFFFFF"  background="#132126"  swipe-threshold @change="getData">

				<van-tab class="active_one" :title="$t('invite.All')" title-style="font-size:13px" >

					<template #title>
						<div style="display: flex;align-items: center;justify-content: center">
							<img v-show="isAll" src="../../static/nft/t15.png" style="width: 24px;margin-right: 4px" />
							<img v-show="!isAll" src="../../static/nft/t15-1.png" style="width: 24px;margin-right: 4px" />
							{{ $t('invite.All') }}
						</div>
					</template>
					<div class="list-box">
						<div class="item-box" v-for="(item, index) of allList" :key="index" @click="gotofnt(item.id,item.addtime)" >
							<div class="status-bg-left-top" v-if="false">
								<img v-if="item.status ==1" src="../../static/nft/t26.png" style="width: 150px;height: 30px"/>
								<img v-else-if="item.status ==0" src="../../static/nft/t27.png" style="width: 150px;height: 30px"/>
							</div>
							<div>
								<img v-if="types==1"  class="van-image__img" :data-src="$configimgurlg + item.img" :src="$configimgurlg + item.img" lazy="loaded"
									  style="object-fit: cover;height: 141px;border-radius: 7px;background: #ffffff;">
								<img v-else  class="van-image__img" :data-src="$configimgurlg + item.img" :src="$configimgurlg + item.img" lazy="loaded"
									  style="object-fit: cover;height: 141px;border-radius: 7px;"> 
							</div>

							<div class="click-like">
								<div style="    white-space: nowrap;display: inline-block;font-size: 14px; font-family: Poppins, Poppins; font-weight: 700;">
									{{item.name}}
								</div>
								<div style="    white-space: nowrap;display: inline-block;    text-align: right;  padding-right: 10px;">
									<div style="display: inline-block">
										<img src="../../static/nft/t24_2.png" style="width: 10px;height: 10px;display: inline-block"/>
										<text class="text-val">{{item.click}} click</text>
									</div>
									<div style="display: inline-block;margin-left: 10px">
										<img src="../../static/nft/t23_2.png" style="width: 10px;height: 10px;display: inline-block"/>
										<text class="text-val">{{item.like}} like</text>
									</div>
								</div>
							</div>

							<div class="money-val">{{item.nft}} ETH</div>

							<div v-if="item.status ==1" class="flex items-center font-bold self-center text-blue-700 new-djs-div">
<!--								<i class="van-badge__wrapper van-icon van-icon-clock-o"> </i>-->
								<img src="../../static/nft/t28.png" style="width: 13px" />
								<div role="timer" class="van-count-down ml-1 text-base text-[#1c4ed8]" style="color: white">{{item.endtimetext}}</div>
<!--								<p class="ml-1">Left</p>-->
							</div>

						</div>
					</div>
		       </van-tab>

		       <van-tab class="active_two" :title="$t('record.OnDesplay')" title-style="font-size:13px" >

				   <template #title>
					   <div style="display: flex;align-items: center;justify-content: center">
						   <img v-show="!isDesplay" src="../../static/nft/t19.png" style="width: 24px;margin-right: 4px" />
						   <img v-show="isDesplay" src="../../static/nft/t19-1.png"
								style="width: 24px;margin-right: 4px" />
						   {{ $t('record.OnDesplay') }}
					   </div>
				   </template>
				   <div class="list-box">
					   <div class="item-box" v-for="(item, index) of allList" :key="index" @click="gotofnt(item.id,item.addtime)" >
						   <div class="status-bg-left-top" v-if="false"><img src="../../static/nft/t26.png" style="width: 150px;height: 30px"/></div>
						   <div>
							   <img v-if="types==1"  class="van-image__img" :data-src="$configimgurlg + item.img" :src="$configimgurlg + item.img" lazy="loaded"
							   	  style="object-fit: cover;height: 141px;border-radius: 7px;background: #ffffff;">
							   <img v-else  class="van-image__img" :data-src="$configimgurlg + item.img" :src="$configimgurlg + item.img" lazy="loaded"
							   	  style="object-fit: cover;height: 141px;border-radius: 7px;"> 
						   </div>

						   <div class="click-like">
							   <div style="    white-space: nowrap;display: inline-block;font-size: 14px; font-family: Poppins, Poppins; font-weight: 700;">
								   {{item.name}}
							   </div>
							   <div style="    white-space: nowrap;display: inline-block;    text-align: right;  padding-right: 10px;">
								   <div style="display: inline-block">
									   <img src="../../static/nft/t24_2.png" style="width: 10px;height: 10px;display: inline-block"/>
									   <text class="text-val">{{item.click}} click</text>
								   </div>
								   <div style="display: inline-block;margin-left: 10px">
									   <img src="../../static/nft/t23_2.png" style="width: 10px;height: 10px;display: inline-block"/>
									   <text class="text-val">{{item.like}} like</text>
								   </div>
							   </div>
						   </div>

						   <div class="money-val">{{item.nft}} ETH</div>

						   <div v-if="item.status ==1" class="flex items-center font-bold self-center text-blue-700 new-djs-div">
							   <!--								<i class="van-badge__wrapper van-icon van-icon-clock-o"> </i>-->
							   <img src="../../static/nft/t28.png" style="width: 13px" />
							   <div role="timer" class="van-count-down ml-1 text-base text-[#1c4ed8]" style="color: white">{{item.endtimetext}}</div>
							   <!--								<p class="ml-1">Left</p>-->
						   </div>

					   </div>
				   </div>



		       </van-tab>

		       <van-tab class="active_three" :title="$t('tab2.Finished')" title-style="font-size:13px">

				   <template #title>
					   <div style="display: flex;align-items: center;justify-content: center">
						   <img v-show="!isFinshed" src="../../static/nft/tab3.png"
								style="width: 24px;margin-right: 4px" />
						   <img v-show="isFinshed" src="../../static/nft/tab3-1.jpg"
								style="width: 24px;margin-right: 4px" />
						   {{ $t('tab2.Finished') }}
					   </div>
				   </template>
				   <div class="list-box">
					   <div class="item-box" v-for="(item, index) of allList" :key="index" @click="gotofnt(item.id,item.addtime)" >
						   <div class="status-bg-left-top" v-if="false"><img src="../../static/nft/t27.png" style="width: 150px;height: 30px"/></div>
						   <div>
							   <img v-if="types==1"  class="van-image__img" :data-src="$configimgurlg + item.img" :src="$configimgurlg + item.img" lazy="loaded"
							   	  style="object-fit: cover;height: 141px;border-radius: 7px;background: #ffffff;">
							   <img v-else  class="van-image__img" :data-src="$configimgurlg + item.img" :src="$configimgurlg + item.img" lazy="loaded"
							   	  style="object-fit: cover;height: 141px;border-radius: 7px;"> 
						   </div>

						   <div class="click-like">
							   <div style="    white-space: nowrap;display: inline-block;font-size: 14px; font-family: Poppins, Poppins; font-weight: 700;">
								   {{item.name}}
							   </div>
							   <div style="    white-space: nowrap;display: inline-block;    text-align: right;  padding-right: 10px;">
								   <div style="display: inline-block">
									   <img src="../../static/nft/t24_2.png" style="width: 10px;height: 10px;display: inline-block"/>
									   <text class="text-val">{{item.click}} click</text>
								   </div>
								   <div style="display: inline-block;margin-left: 10px">
									   <img src="../../static/nft/t23_2.png" style="width: 10px;height: 10px;display: inline-block"/>
									   <text class="text-val">{{item.like}} like</text>
								   </div>
							   </div>
						   </div>

						   <div class="money-val">{{item.nft}} ETH</div>

						   <div v-if="item.status ==1" class="flex items-center font-bold self-center text-blue-700 new-djs-div">
							   <!--								<i class="van-badge__wrapper van-icon van-icon-clock-o"> </i>-->
							   <img src="../../static/nft/t28.png" style="width: 13px" />
							   <div role="timer" class="van-count-down ml-1 text-base text-[#1c4ed8]" style="color: white">{{item.endtimetext}}</div>
							   <!--								<p class="ml-1">Left</p>-->
						   </div>

					   </div>
				   </div>
		       </van-tab>

		     </van-tabs>
		</div>
		<Service />
    </div>
</template>

<script>
    // 功能引入
	import {
		toRefs,
		reactive,
		onMounted,
		onUnmounted,
		watch,
		getCurrentInstance
	} from 'vue'
    import {financinlist, financing} from '@/api/tab2'
    import {initIndex} from '../api/tab4'
    import {showToast} from 'vant';
	import { useRoute } from 'vue-router'
	import Service from '@/components/Service'

    export default {
        components: {Service},
        setup() {
			
			const { proxy }  = getCurrentInstance()
			
			const route = useRoute()
			const type = route.query.type ? route.query.type : 0
            // 数据
            const State = reactive({
				active:0,
				showOops: false,
				params:{type:0,status:0},
				temp:'',
				isAll: true,
				isDesplay: false,
				isFinshed: false,
                allList: [],//所以质押列表
				suijishu:[],
				types:''
            });
			watch(() => State.active, (newVal, oldVal) => {
				if (newVal === 0) {
					State.isAll = true;
					State.isDesplay = false;
					State.isFinshed = false;
				} else if (newVal === 1) {
					State.isAll = false;
					State.isDesplay = true;
					State.isFinshed = false;
				} else if (newVal === 2) {
					State.isAll = false;
					State.isDesplay = false;
					State.isFinshed = true;
				}
			});
            // 方法
            const Function = reactive({
				goToOneTab1() {
					this.$router.push({
						path: "/tab1"
					});
				},
				goToOneTab2() {
                this.$router.push({
                    path: "/tab2_two",
					query: {type:type}
                });
            },
				getData(active) {
					State.types = type
					State.params.type = type
					if(active==1){
						//进行中
						State.params.status = 1
					} else if(active==2){
						//已结束
						State.params.status = -1
					} else if(active==0){
						//全部
						State.params.status = 0
					}
					clearInterval(State.temp)
					financinlist(State.params).then(res => {
						if(res.data){
							Function.suijizhengshu(res.data)
						} else {
							State.showOops = true
						}

					}).catch((e) => {
						console.log(e)
					})
				},
				suijizhengshu(array){
					// array.forEach((item,index)=>{
					// 	let sui = Math.floor(Math.random() * (999 - 100 + 1) ) + 100
					// 	let suis = Math.floor(Math.random() * (999 - 100 + 1) ) + 100
					// 	State.suijishu.push({'clik':sui,'like':suis})
					// });
					Function.daojishi(array)
				},
				daojishi(array){
					State.allList = array
					State.temp = setInterval(()=>{
						State.allList.forEach((item,index)=>{
							if(item.status==1){
								//let endtimetext = Function.timer(item.countdown)
								let endtimetext = Function.timer(item.endtime)
								State.allList[index]['endtimetext'] = endtimetext
							}
						});
					},1000);
				},
				timer(endtime){
					let nowtime = new Date().getTime();
					let timestamp = new Date(endtime).getTime();
					//let timestamp = endtime * 1000;
					if(timestamp >= nowtime){
						let timediff = Math.round((timestamp - nowtime) / 1000)
						let hour = parseInt((timediff / 3600) % 24)
						let hous = hour < 10 ? '0' + hour : hour
						let minute = parseInt((timediff / 60) % 60)
						let minutes = minute < 10 ? '0' + minute : minute
						let second = timediff % 60
						let seconds = second < 10 ? '0' + second : second
						return hous + ':' + minutes + ':' + seconds
					} else {
						return '00:00:00'
					}
				},
				gotofnt(id,kstimes){
					/*
					this.$router.push({
					    path: "/tab3",
					    query: {id: id,type:type}
					});
					*/
				   /*
				   let timestampx = new Date(kstimes).getTime() + (6 * 60 * 60 * 1000)
				   let nowtimesx = new Date().getTime()
				   if(nowtimesx > timestampx){
						showToast(proxy.$t('tab2.tip'))
						return false
				   }
				   */
				   this.$router.push({
				       path: "/hashmask",
				       query: {id: id,type:type}
				   });
				}
            });
            // 接口
            const Interface = reactive({});

            onMounted(() => {
                Function.getData()
            });
			onUnmounted(() => {
                //销毁
				clearInterval(State.temp)
            });
            return {
                ...toRefs(State),
                ...toRefs(Function),
                ...toRefs(Interface),
            }
        },
    }
</script>

<style type="text/css" scoped>

	@import '../../static/nft/nft-coin.icu_assets_index-e8285ab1.css';

    .app-main {
        width: 100%;
        height: 100%;
        position: fixed;
        color: white;
        overflow-y: auto;
		background: #0F0B25;
		background-image: linear-gradient(135deg, #310A56 10%, #1A32A4 100%);
    }

	.oops {
	    width: 100%;
	    background: linear-gradient(225deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.2) 100%);
	    border-image: linear-gradient(225deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) 1 1;
	    backdrop-filter: blur(15px);
	    margin-bottom: 125%;
	    position: relative;
		background: none;
	}

	.text-\[\#1c4ed8\] {
	    color: #2452c9;
	}
	.loads{
		text-align: center;
		line-height: 50px;
	}


	/deep/ .van-tabs__nav{
		background: #0F0B25 !important;
		background: none !important;
	}
	/deep/ .van-tab__panel{
		background: #0F0B25 !important;
	}
	/deep/ .van-tab--active {
		background: white;
		color: #1D1F2B !important;
		border-radius: 10px;
	}
	/deep/ .van-tabs__line{
		background: none !important;
	}

	.list-box {
		width: 100%;
		padding: 0 16px;
		/*margin-left: 16px;*/
		margin-top: 24px;
		overflow-y: scroll;
		position: absolute;
		height: calc(100vh - 120px);
	}
	.item-box{
		width: 100%;
		height: 242px;
		height: 220px;
		margin-bottom: 10px;
		clip-path: border-box;
		padding: 12px 16px 16px 16px;
		background: url("/static/nft/t30.png");
		/*background-size: 100%;*/
		background-size: 100% 100%;
		position: relative;
	}

	.click-like{
		color: rgba(153, 153, 153, 1);
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 8px 0;
	}
	.text-val{
		position: relative;
		left: 4px;
		top: 2px;
	}
	.money-val{
		color: rgba(131, 104, 241, 1);
		font-size: 12px;
		font-family: Poppins, Poppins;
		font-weight: 400;
		position: relative;
	}
	.new-djs-div{
		position: absolute;
		right: 22px;
		top: 18px;
		width: 104px;
		height: 24px;
		border-radius: 8px;
		background: rgba(15, 24, 46, 1);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.status-bg-left-top{
		width: 160px;
		height: 30px;
		position: absolute;
		top: 0px;
	}
</style>
