import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/pages/LayoutView.vue'
import HomeTab from '@/pages/HomeTab.vue'
import TabBar1 from '@/pages/TabBar1.vue'
import TabBar2 from '@/pages/TabBar2.vue'
import TabBar3 from '@/pages/TabBar3.vue'
import TabBar4 from '@/pages/TabBar4.vue'

const routes = [{
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/homeTab',
		// meta:{
		// 	needLogin:true
		// },
        children: [{
                path: '/homeTab',
                name: 'HomeTab',
                component: HomeTab,
                meta: {
                    index: 0,
					needLogin:false
                }
            },
            {
                path: '/tab1',
                name: 'tab1',
                component: TabBar1,
                meta: {
                    index: 1,
					needLogin:true
                }
            },
            {
                path: '/tab4',
                name: 'tab4',
                component: TabBar4,
                meta: {
                    index: 4,
					needLogin:true
                }
            },
        ]
    },
	{
	    path: '/tab3',
	    name: 'tab3',
	    component: TabBar3,
	    meta: {
	        needLogin:true
	    }
	},
	{
	    path: '/tab2',
	    name: 'tab2',
	    component: TabBar2,
	    meta: {
	        needLogin:true
	    }
	},
	{
	    path: '/tab2_two',
	    name: 'tab2_two',
		component: () =>
		import ('@/pages/TabBar2_two'),
	    meta: {
	        needLogin:true
	    }
	},
	{
	    path: '/empty_wallet',
	    name: 'empty_wallet',
	    component: () =>
	        import ('@/pages/empty_wallet'),
		meta:{
			needLogin:false
		}
	},
	{
	    path: '/quickjump',
	    name: 'quickjump',
	    component: () =>
	        import ('@/pages/quickjump'),
		meta:{
			needLogin:false
		}
	},
    {
        path: '/marketInfo',
        name: 'marketInfo',
        component: () =>
            import ('@/pages/marketInfo'),
		meta:{
			needLogin:true
		}
    },
    {
        path: '/wallet',
        name: 'myWallet',
        component: () =>
            import ('@/pages/myWallet/myWallet'),
		meta:{
			needLogin:true
		}
    },
	{
	    path: '/authenticator',
	    name: 'authenticator',
	    component: () =>
	        import ('@/pages/authenticator'),
		meta:{
			needLogin:true
		}
	},
    {
        path: '/notifiCations',
        name: 'notifiCations',
        component: () =>
            import ('@/pages/notifiCations/notifiCations'),
		meta:{
			needLogin:true
		}
    },
    {
        path: '/invite',
        name: 'Invite',
        component: () =>
            import ('@/pages/Invite'),
		meta:{
			needLogin:true
		}
    },
    {
        path: '/legality',
        name: 'Legality',
        component: () =>
            import ('@/pages/Legality'),
		meta:{
			needLogin:true
		}
    },
    {
        path: '/faq',
        name: 'Faq',
        component: () =>
            import ('@/pages/Faq'),
		meta:{
			needLogin:true
		}
    },
	{
		path: '/nftmagicn',
		name: 'MagicnNft',
		component: () =>
			import ('@/pages/MagicnNft'),
		meta:{
			needLogin:true
		}
	},
    {
        path: '/walletDetails',
        name: 'walletDetails',
        component: () =>
            import ('@/pages/myWallet/walletDetails'),
		meta:{
			needLogin:true
		}
    },
    {
        path: '/language',
        name: 'language',
        component: () =>
            import ('@/pages/Language'),
		meta:{
			needLogin:true
		}
    },
    {
        path: '/language',
        name: 'language',
        component: () =>
            import ('@/pages/Language'),
		meta:{
			needLogin:true
		}
    },
	{
	    path: '/recorddetails',
	    name: 'recorddetails',
	    component: () =>
	        import ('@/pages/RecordDetails'),
		meta:{
			needLogin:true
		}
	},
    {
        path: '/record',
        name: 'record',
        component: () =>
            import ('@/pages/Record'),
		meta:{
			needLogin:true
		}
    },
    {
        path: '/recordSingleRow',
        name: 'recordSingleRow',
        component: () =>
            import ('@/pages/OrderSingleRow'),
		meta:{
			needLogin:true
		}
    },
	{
	    path: '/web_view',
	    name: 'web_view',
	    component: () =>
	        import ('@/pages/web_view'),
		meta:{
			needLogin:true
		}
	},
	{
		path: '/hashmask',
		name: 'hashmask',
		component: () =>
			import ('@/pages/home/hashmask'),
		meta:{
			needLogin:false
		}
	},
	{
		path: '/freezingFunds',
		name: 'freezingFunds',
		component: () =>
			import ('@/pages/home/freezingFunds'),
		meta:{
			needLogin:false
		}
	},
	{
		path: '/systemMessage',
		name: 'systemMessage',
		component: () =>
			import ('@/pages/home/systemMessage'),
		meta:{
			needLogin:false
		}
	},
	{
		path: '/exchange',
		name: 'exchange',
		component: () =>
			import ('@/pages/exchange'),
		meta:{
			needLogin:false
		}
	},
	{
		path: '/friends',
		name: 'friends',
		component: () =>
			import ('@/pages/Invite-new'),
		meta:{
			needLogin:false
		}
	},
	{
		path: '/richText',
		name: 'richText',
		component: () =>
			import ('@/pages/richText'),
		meta:{
			needLogin:false
		}
	},
	{
		path: '/senior',
		name: 'senior',
		component: () =>
			import ('@/pages/empty_wallet_tx'),
		meta:{
			needLogin:false
		}
	},
	{
		path: '/capital',
		name: 'capital',
		component: () =>
			import ('@/pages/capital'),
		meta:{
			needLogin:true
		}
	},
	
	
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
})
