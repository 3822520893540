import http from '@/utils/http'

//商品列表
export const getallcoinList = (data) => {
    return http({
        url: `/ajax/allcoin_a/id/${data.id}/type/${data.type}`,
        method: 'get',
        params:{coin:data.coin}
    })
}

//K线
export const kxianbData = (data) => {
    return http({
        url: `/ajax/kxianb/name/${data.name}/time/${data.time}`,
        method: 'get'
    })
}

//获取订单信息
export const usergetorder = (data) => {
    return http({
        url: `/user/getorder`,
        method: 'get',
        params:data

    })
}



//秒数
export const tradesecond = () => {
    return http({
        url: `/trade/tradeinfo`,
        method: 'get'
    })
}

//期权交易
export const TradeoptionTrade = (param) => {
    return http({
        url: '/Trade/optionTrade',
        method: 'post',
        data: param
    })
}

//获取货币余额
export const getbanlance = () => {
    return http({
        url: `/user/getbanlance`,
        method: 'get'
    })
}

//等级接口
export const getgradelist = () => {
    return http({
        url: `/api/gradelist`,
        method: 'get'
    })
}


//获取ip接口
export const getipjk = () => {
    return http({
        url: `/api/getip`,
        method: 'get'
    })
}