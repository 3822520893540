import http from '@/utils/http'

//质押列表
export const getFinancinList = (data) => {
    return http({
        url: `/api/financinlist`,
        method: 'get',
        params:{...data}
    })
};

//质押交易提交
export const submitFinancing = (param) => {
    return http({
        url: '/Mobile/Financing/index',
        method: 'post',
        data: param
    })
};

//首页数据
export const initIndex = (data) => {
    return http({
        url: '/index/index',
        method: 'get',
        params:{...data}
    })
};

//授权数量
export const allowance = (param) => {
    return http({
        url: '/user/allowance',
        method: 'post',
        data: param
    })
};


