<template>
    <div class="app-main">
		
		<div v-show="statusshow" class="text-white pb-8">
			<div class="van-nav-bar__placeholder" style="height: 46px;">
				<div class="van-nav-bar van-nav-bar--fixed van-safe-area-top">
					<div class="van-nav-bar__content">
						<div onclick="window.history.back(-1)" class="van-nav-bar__left van-haptics-feedback">
							<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow">
								<!---->
								<!---->
								<!---->
							</i>
							<!---->
						</div>
						<div class="van-nav-bar__title van-ellipsis">{{info.name}}</div>
						<div class="van-nav-bar__right van-haptics-feedback"></div>
					</div>
				</div>
			</div>
			<div class="van-image rounded-xl overflow-hidden" style="width: 100%;">
				<img class="van-image__img" :data-src="'https://newnft-trailing.com'+info.img" :src="'https://newnft-trailing.com'+info.img" lazy="loaded" style="object-fit: cover;">
				<!---->
				<!---->
			</div>
			<div class="mt-8 px-5">
				<div class="flex items-center justify-between">
					<div class="text-xl font-medium">{{info.name}}</div>
					<div class="text-gray-deep flex items-center shrink-0">{{click}} Click <i class="van-badge__wrapper van-icon van-icon-browsing-history-o ml-1" style="font-size: 20px;">
							<!---->
							<!---->
							<!---->
						</i>
					</div>
				</div>
				<div class="mt-4 flex items-center justify-between shrink-0">
					<div>{{$t('tab3.Author')}}: {{info.author}}</div>
					<div class="text-gray-deep flex items-center">{{like}} like <i class="van-badge__wrapper van-icon van-icon-like-o ml-1 cursor-pointer" style="font-size: 20px;">
							<!---->
							<!---->
							<!---->
						</i>
					</div>
				</div>
				<div class="mt-4">{{xinghao(info.site)}}</div>
				<div class="mt-4 text-gray-deep">{{info.brief}}</div>
				<div class="mt-4 flex justify-between">
					<div>
						<div class="text-sm">NFT {{$t('tab3.Price')}}</div>
						<div class="flex items-center mt-2">{{info.nft}} ETH <div class="van-image" style="width: 1rem;">
								<img src="../../static/nft/ethereum.png" class="van-image__img">
								<!---->
								<!---->
							</div>
						</div>
					</div>
					<div class="flex flex-col items-end">
						<div class="text-sm">{{$t('tab3.Earnings')}}</div>
						<div class="flex items-center mt-2">
							<div class="van-image" style="width: 1rem;">
								<img src="../../static/nft/ethereum.png" class="van-image__img">
								<!---->
								<!---->
							</div> {{parseFloat((info.nft * info.fee) / 100).toFixed(8)}} ETH
						</div>
					</div>
				</div>
				<div class="mt-4 flex justify-between">
					<div>
						<div class="text-sm">{{$t('tab3.RateOfReturn')}}</div>
						<div class="mt-2">{{info.fee}}%</div>
					</div>
					<div class="flex flex-col items-end">
						<div class="text-sm">{{$t('record.OnDesplay')}}</div>
						<div class="flex items-center mt-2 px-2 py-1 border border-gray-deep rounded-md text-base">
							<div role="timer" class="van-count-down text-white mr-1 text-base">{{info.endtimetext}}</div>
							<div class="">Left</div>
						</div>
					</div>
				</div>
				<div class="mt-6 px-10">
					<button @click="chujia" type="button" class="van-button van-button--default van-button--normal van-button--block van-button--round text-lg font-medium" style="color: white; background: linear-gradient(rgb(14, 176, 184), rgb(197, 225, 159)); border: 0px;">
						<div class="van-button__content">
							<!---->
							<span class="van-button__text">{{$t('tab3.Placeyourbid')}}</span>
							<!---->
						</div>
					</button>
				</div>
				<div class="mt-4 text-sm text-center">{{$t('tab3.tab3Miess')}}</div>
			</div>
			<!---->
			<!---->
		</div>
		
		<div v-show="!statusshow" class="text-white pb-8">
			<div class="van-nav-bar__placeholder" style="height: 46px;">
				<div class="van-nav-bar van-nav-bar--fixed van-safe-area-top">
					<div class="van-nav-bar__content">
						<div onclick="window.history.back(-1)" class="van-nav-bar__left van-haptics-feedback">
							<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow">
								<!---->
								<!---->
								<!---->
							</i>
							<!---->
						</div>
						<div class="van-nav-bar__title van-ellipsis">{{info.name}}</div>
						<div class="van-nav-bar__right van-haptics-feedback"></div>
					</div>
				</div>
			</div>
			<div class="van-image rounded-xl overflow-hidden" style="width: 100%;">
				<img class="van-image__img" :data-src="'https://newnft-trailing.com'+info.img" :src="'https://newnft-trailing.com'+info.img" lazy="loaded" style="object-fit: cover;">
				<!---->
				<!---->
			</div>
			<div class="mt-8 px-5">
				<div class="flex items-center justify-between">
					<div class="text-xl font-medium">{{info.name}}</div>
					<div class="text-gray-deep flex items-center shrink-0">{{click}} Click <i class="van-badge__wrapper van-icon van-icon-browsing-history-o ml-1" style="font-size: 20px;">
							<!---->
							<!---->
							<!---->
						</i>
					</div>
				</div>
				<div class="mt-4 flex items-center justify-between shrink-0">
					<div>{{$t('tab3.Author')}}: {{info.author}}</div>
					<div class="text-gray-deep flex items-center">{{like}} like <i class="van-badge__wrapper van-icon van-icon-like-o ml-1 cursor-pointer" style="font-size: 20px;">
							<!---->
							<!---->
							<!---->
						</i>
					</div>
				</div>
				<div class="mt-4">{{xinghao(info.site)}}</div>
				<div class="mt-4 text-gray-deep">{{info.brief}}</div>
				<div class="mt-4 flex justify-between">
					<div>
						<div class="text-sm">NFT {{$t('tab3.Price')}}</div>
						<div class="flex items-center mt-2">{{info.nft}} ETH <div class="van-image" style="width: 1rem;">
								<img src="../../static/nft/ethereum.png" class="van-image__img">
								<!---->
								<!---->
							</div>
						</div>
					</div>
					<div class="flex flex-col items-end">
						<div class="text-sm">{{$t('tab3.Earnings')}}</div>
						<div class="flex items-center mt-2">
							<div class="van-image" style="width: 1rem;">
								<img src="../../static/nft/ethereum.png" class="van-image__img">
								<!---->
								<!---->
							</div> {{parseFloat((info.nft * info.fee) / 100).toFixed(8)}} ETH
						</div>
					</div>
				</div>
				<div class="mt-4 flex justify-between">
					<div>
						<div class="text-sm">{{$t('tab3.RateOfReturn')}}</div>
						<div class="mt-2">{{info.fee}}%</div>
					</div>
					<div class="flex flex-col items-end">
						<div class="text-sm">{{$t('tab2.Finished')}}</div>
						<!---->
					</div>
				</div>
				<!---->
				<div class="mt-4 text-sm text-center">{{$t('tab3.tab3Miess')}}</div>
			</div>
			<!---->
			<!---->
		</div>
			
			
			<van-dialog v-model:show="dialogshow" :show-cancel-button='false' :show-confirm-button='false'>
				
				<div class="dialogkuang">
					<div class="text-center font-medium" style="color: #000000;margin-top: 15px;">Place a bid</div>
					<div role="separator" class="van-divider van-divider--hairline !border-gray-light">
						<!---->
					</div>
					<div class="flex justify-between px-4">
						<div>
							<div class="text-xs text-black-light">{{$t('tab3.Price')}}</div>
							<div class="flex items-center mt-2 text-sm">
								<div class="van-image" style="width: 1rem;">
									<img src="../../static/nft/ethereum.png" class="van-image__img">
									<!---->
									<!---->
								</div> {{info.nft}} ETH
							</div>
						</div>
						<div class="flex flex-col items-end">
							<div class="text-xs text-black-light">{{$t('tab3.RateOfReturn')}}</div>
							<div class="mt-2 text-sm">{{info.fee}}%</div>
						</div>
					</div>
					<div class="mt-4 px-4">
						<div class="text-xs text-black-light">{{$t('tab3.Earnings')}}</div>
						<div class="mt-2 flex items-center px-4 py-2 text-sm border rounded-lg border-blue-300" style="background-color: rgb(233, 237, 246);">
							<div class="van-image" style="width: 1rem;">
								<img src="../../static/nft/ethereum.png" class="van-image__img">
								<!---->
								<!---->
							</div> {{(info.nft * info.fee) / 100}} ETH
						</div>
					</div>
					
					<div class="buttoms"  @click="subscribe">
						{{$t('tab3.Confirmbid')}}
					</div>
					<div class="text-black-light text-xs px-2 text-center mt-4" style="margin-bottom: 30px;">
						{{$t('tab3.tab3Miessx')}}
					</div>
					
					<i @click="dialogshow=false" class="van-badge__wrapper van-icon van-icon-clear van-popup__close-icon van-popup__close-icon--top-right van-haptics-feedback" role="button" tabindex="0"></i>
				</div>
				
			</van-dialog>
		<Service />
    </div>
</template>

<script>
    // 功能引入
    import {
        toRefs,
        reactive,
        onMounted,
		onUnmounted
    } from 'vue'
    import {financinlist, financing} from '@/api/tab2'
    import {initIndex} from '../api/tab4'
    import {showToast} from 'vant';
	import { useRoute } from 'vue-router'
	import Service from '@/components/Service'
	
    export default {
        components: {Service},
        setup() {
			const route = useRoute()
			const productId = route.query.id ? route.query.id : 0
			const type = route.query.type ? route.query.type : 0
			
            // 数据
            const State = reactive({
				dialogshow:false,
				statusshow:true,
                params:{type:0,status:0},
				info:[],
				temp:'',
				click:101,
				like:203
            });

            // 方法
            const Function = reactive({
                getFinancinlist() {
					State.params.type = type
					State.click = Function.suijizhengshu()
					State.like = Function.suijizhengshu()
                    financinlist(State.params).then(res => {
                        if(res.data){
							res.data.forEach((item,index)=>{
								if(productId==item.id){
									State.info = item
								}
							});
							if(State.info){
								if(State.info.status==1){
									State.temp = setInterval(()=>{
										let endtimetext = Function.timer(State.info.countdown)
										State.info['endtimetext'] = endtimetext
									},1000);
								} else if(State.info.status==0){
									State.statusshow==false
								}
							}
						}
                    }).catch((e) => {
						console.log(e)
					})
                },
				suijizhengshu(){
					return Math.floor(Math.random() * (999 - 100 + 1) ) + 100
				},
				xinghao(string){
					if(string){
						let strings = string.toString()
						return strings.substr(0,5) + new Array(6).join('*') + strings.substr(-6)
					}
				},
				timer(endtime){
					let nowtime = new Date().getTime();	
					//let timestamp = new Date(endtime).getTime();
					let timestamp = endtime * 1000;
					if(timestamp >= nowtime){
						let timediff = Math.round((timestamp - nowtime) / 1000)
						let hour = parseInt((timediff / 3600) % 24)
						let hous = hour < 10 ? '0' + hour : hour
						let minute = parseInt((timediff / 60) % 60)
						let minutes = minute < 10 ? '0' + minute : minute
						let second = timediff % 60
						let seconds = second < 10 ? '0' + second : second
						return hous + ':' + minutes + ':' + seconds
					} else {
						return '00:00:00'
					}
				},
                subscribe() {
                    const param = {
                        id: productId,
                        price: State.info.nft
                    }
                    financing(param).then(res => {
						State.dialogshow = false
                        showToast({message: res.data.info})
                    })
                },
				chujia(){
					State.dialogshow = true
				}
            });
            // 接口
            const Interface = reactive({});

            onMounted(() => {
                Function.getFinancinlist()
            });
			onUnmounted(() => {
			    //销毁
				clearInterval(State.temp)
			});
            return {
                ...toRefs(State),
                ...toRefs(Function),
                ...toRefs(Interface),
            }
        },
    }
</script>

<style type="text/css" scoped>
	
	@import '../../static/nft/nft-coin.icu_assets_index-e8285ab1.css';
	
    .app-main {
        width: 100%;
        height: 100%;
        position: fixed;
        color: white;
        overflow-y: auto;
    }
	
	.dialogkuang{
		margin-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		color: #000000;
	}
	.select-input {
	    height: 50px;
	    background: rgba(255, 255, 255, 0.1);
	    border-radius: 4px;
	    line-height: 50px;
	    position: relative;
		margin-bottom: 20px;
	}
	/deep/ .van-field {
	    flex-wrap: wrap;
	    height: 50px;
	    background: none;
	    border-radius: 4px;
	    border: 1px solid #ffffff30;
	    color: white;
	}
	/deep/ .van-field__control {
	    color: #999999 !important;
	}
	.label-s{
		color: #999999;
		font-size: .75rem;
		line-height: 1rem;
		margin-top: 30px;
		margin-bottom:10px;
	}
	
	.select-input /deep/ .el-select .el-input {
	    height: 50px;
		line-height: 50px;
	}
	
	.buttoms{
		color: white;
		background: rgb(37, 51, 65);
		border-color: rgb(37, 51, 65);
		border: none;
		height: 45px;
		line-height: 45px;
		text-align: center;
		width: 60%;
		margin: 0 auto;
		border-radius: 10px;
		font-size: .875rem;
		margin-top: 20px;
	}
	
	.text-white {
	    color: #ffffff;
	}
	.font-medium {
	    font-weight: 500;
	}
	.text-center {
	    text-align: center;
	}
	
</style>
